import { ChecklistIcon, SearchIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { ComponentProps, Suspense } from 'react';
import { graphql } from 'react-relay';

import EmptyState from '../../components/core/EmptyState';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import AssignedTaskStatusTabList from '../../components/task/AssignedTaskStatusTabList';
import AssignedTaskWindowPaginator from '../../components/task/AssignedTaskWindowPaginator';
import TaskConnectionDataTable from '../../components/task/TaskConnectionDataTable';
import { usePaginationContext } from '../../contexts/PaginationContext';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import {
  assignedTask_assignedTasksQuery,
  TaskStatusEnum,
} from '../../relay/__generated__/assignedTask_assignedTasksQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { normalizeObject } from '../../utils/object';
import { NextPage } from '../_app';

const assignedTasksForAssignedTask = graphql`
  query assignedTask_assignedTasksQuery($filters: TaskFilter, $order: TaskOrder, $page: Int, $pageSize: Int) {
    ...AssignedTaskWindowPaginator_query @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

type Props = {};

const AssignedTask: NextPage<Props> = () => {
  const router = useRouter();

  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    status_Exact: { type: 'string' },
    order: { type: 'string' },
    page: { type: 'number' },
  });
  const { pageSize } = usePaginationContext();

  const { search, status_Exact, order, page } = initialValues;

  return (
    <View>
      <Head title={'TCMS'} siteTitle={'배정된 업무'} />
      <ErrorBoundary>
        <QueryFormik<assignedTask_assignedTasksQuery>
          query={assignedTasksForAssignedTask}
          staticVariables={{ pageSize }}
          initialValues={{
            filters: {
              search,
              status_Exact: status_Exact as TaskStatusEnum,
            },
            order: order || 'assigned',
            page,
          }}
          options={{ fetchPolicy: 'store-and-network' }}
          onSubmit={(values) => setParsedUrlQuery({ ...values.filters, order }, { scroll: false })}
          enableReinitialize
        >
          {({ values: { filters, order }, setFieldValue, submitForm }, queryReference) => {
            const handleChangeOrderActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
              newValue,
            ) => {
              setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
            };

            return (
              <View>
                <Text as={'h1'}>배정된 업무</Text>
                <Suspense>
                  <AssignedTaskStatusTabList
                    selectedId={status_Exact}
                    onSelect={({ value }) => {
                      setParsedUrlQuery({ ...filters, status_Exact: value, order });
                    }}
                  />
                </Suspense>
                <View sx={{ marginTop: 5 }}>
                  <Grid>
                    <Grid.Unit size={'min'}>
                      <QueryFormik.FilterSearchTextField
                        typename={'TaskFilter'}
                        label={'Search'}
                        labelConfig={{ visuallyHidden: true }}
                        name={'filters.search'}
                        size={'large'}
                        autoComplete={'off'}
                        leadingVisual={SearchIcon}
                        debounce
                        onChange={(e) => {
                          setFieldValue('filters.search', e.target.value);
                          setTimeout(() => submitForm(), 0);
                        }}
                      />
                    </Grid.Unit>
                    <Grid.Unit size={'max'}>
                      <View sx={{ display: 'flex', justifyContent: 'end' }}>
                        <QueryFormik.OrderActionMenuButton
                          typename={'TaskOrder'}
                          orders={['assigned']}
                          onChange={handleChangeOrderActionMenu}
                        />
                      </View>
                    </Grid.Unit>
                  </Grid>
                </View>
                <View sx={{ marginTop: 5 }}>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<assignedTask_assignedTasksQuery>>
                        {(queryReference) => (
                          <AssignedTaskWindowPaginator
                            fragmentReference={queryReference}
                            onLoadPage={(page) => setParsedUrlQuery({ ...filters, order, page })}
                          >
                            {({ assignedTasks }, { renderPagination }) => (
                              <>
                                <View>
                                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                    총 {numberWithCommas(assignedTasks.totalCount || 0)}개
                                  </Text>
                                </View>
                                <View sx={{ marginTop: 3 }}>
                                  <TaskConnectionDataTable
                                    taskConnection={assignedTasks}
                                    onRowClick={({ id, assignedTo }) => {
                                      const pathname = `/task/${id}`;
                                      router.push(
                                        {
                                          pathname,
                                          query: normalizeObject({
                                            ...filters,
                                            assignedToId_Exact: assignedTo?.id,
                                            order,
                                          }),
                                        },
                                        pathname,
                                      );
                                    }}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState
                                          title={'결과가 없어요'}
                                          description={'다른 필터로 다시 시도해보세요.'}
                                        />
                                      </View>
                                    }
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.()}
                                </View>
                              </>
                            )}
                          </AssignedTaskWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            );
          }}
        </QueryFormik>
      </ErrorBoundary>
    </View>
  );
};

AssignedTask.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
AssignedTask.authenticated = true;
AssignedTask.routes = [
  {
    id: 'assignedTask',
    pathname: '/assignedTask',
    name: '배정된 업무',
    icon: ChecklistIcon,
    permissions: ['assigned_task_read'],
  },
];

export default AssignedTask;
