import { DependabotIcon, SearchIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { ComponentProps, Suspense } from 'react';
import { graphql } from 'react-relay';

import EmptyState from '../../components/core/EmptyState';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import TypingAutomationScriptConnectionDataTable from '../../components/typingAutomationScript/TypingAutomationScriptConnectionDataTable';
import TypingAutomationScriptWindowPaginator from '../../components/typingAutomationScript/TypingAutomationScriptWindowPaginator';
import { usePaginationContext } from '../../contexts/PaginationContext';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import { typingAutomationScript_typingAutomationScriptsQuery } from '../../relay/__generated__/typingAutomationScript_typingAutomationScriptsQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { NextPage } from '../_app';

const typingAutomationScriptForTypingAutomationScript = graphql`
  query typingAutomationScript_typingAutomationScriptsQuery(
    $filters: TypingAutomationScriptFilter
    $order: TypingAutomationScriptOrder
    $page: Int
    $pageSize: Int
  ) {
    ...TypingAutomationScriptWindowPaginator_query
      @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

type Props = {};

const TypingAutomationScript: NextPage<Props> = () => {
  const router = useRouter();

  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    order: { type: 'string' },
    page: { type: 'number' },
  });
  const { search, order, page } = initialValues;

  const { pageSize } = usePaginationContext();

  return (
    <View>
      <Head siteTitle={'타이핑 프롬프트'} />
      <ErrorBoundary>
        <QueryFormik<typingAutomationScript_typingAutomationScriptsQuery>
          query={typingAutomationScriptForTypingAutomationScript}
          staticVariables={{ pageSize }}
          initialValues={{
            filters: { search },
            order: order || '-created',
            page,
          }}
          options={{ fetchPolicy: 'store-and-network' }}
          onSubmit={(values) => setParsedUrlQuery({ ...values.filters, order }, { scroll: false })}
          enableReinitialize
        >
          {({ values: { filters, order }, setFieldValue, submitForm }, queryReference) => {
            const handleChangeActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
              newValue,
            ) => {
              setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
            };

            return (
              <View>
                <Text as={'h1'}>타이핑 프롬프트</Text>
                <Grid>
                  <Grid.Unit size={'min'}>
                    <QueryFormik.FilterSearchTextField
                      typename={'RaffleFilter'}
                      label={'Search'}
                      labelConfig={{ visuallyHidden: true }}
                      name={'filters.search'}
                      size={'large'}
                      autoComplete={'off'}
                      leadingVisual={SearchIcon}
                      debounce
                      onChange={(e) => {
                        setFieldValue('filters.search', e.target.value);
                        setTimeout(() => submitForm(), 0);
                      }}
                    />
                  </Grid.Unit>
                  <Grid.Unit size={'max'}>
                    <View sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Stack gapX={2}>
                        <Stack.Item>
                          <QueryFormik.OrderActionMenuButton
                            typename={'TypingAutomationScriptOrder'}
                            orders={['created']}
                            onChange={handleChangeActionMenu}
                          />
                        </Stack.Item>
                      </Stack>
                    </View>
                  </Grid.Unit>
                </Grid>
                <View sx={{ marginTop: 5 }}>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<typingAutomationScript_typingAutomationScriptsQuery>>
                        {(queryReference) => (
                          <TypingAutomationScriptWindowPaginator
                            fragmentReference={queryReference}
                            onLoadPage={(page) => setParsedUrlQuery({ ...filters, order, page })}
                          >
                            {({ typingAutomationScripts }, { renderPagination }) => (
                              <>
                                <View>
                                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                    총 {numberWithCommas(typingAutomationScripts.totalCount || 0)}개
                                  </Text>
                                </View>
                                <View sx={{ marginTop: 3 }}>
                                  <TypingAutomationScriptConnectionDataTable
                                    typingAutomationScriptConnection={typingAutomationScripts}
                                    onRowClick={({ id }) => router.push({ pathname: `/typingAutomationScript/${id}` })}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState title={'프롬프트가 없어요'} />
                                      </View>
                                    }
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.()}
                                </View>
                              </>
                            )}
                          </TypingAutomationScriptWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            );
          }}
        </QueryFormik>
      </ErrorBoundary>
    </View>
  );
};

export default TypingAutomationScript;

TypingAutomationScript.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
TypingAutomationScript.authenticated = true;
TypingAutomationScript.routes = [
  {
    id: 'typingAutomationScript',
    name: '타이핑 프롬프트',
    pathname: '/typingAutomationScript',
    icon: DependabotIcon,
    permissions: ['typing_automation_script_read'],
  },
  // ...RaffleId.routes,
];
