import { ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon, KebabHorizontalIcon } from '@primer/octicons-react';
import { Select, themeGet } from '@primer/react';
import { useRef } from 'react';
import { graphql, useFragment } from 'react-relay';
import styled from 'styled-components';

import { usePaginationContext } from '../../../contexts/PaginationContext';
import useToast from '../../../hooks/useToast';
import { Pagination_pages$key } from '../../../relay/__generated__/Pagination_pages.graphql';
import ItemList from '../../core/ItemList';
import NumberInput from '../../core/NumberInput';
import Stack from '../../core/Stack';
import StyledOcticon from '../../core/StyledOcticon';
import Text from '../../core/Text';
import TextInput from '../../core/TextInput';

const Pagination_pages = graphql`
  fragment Pagination_pages on Pages {
    first {
      page
    }
    around {
      page
      isCurrent
    }
    last {
      page
    }
    next {
      page
    }
    previous {
      page
    }
  }
`;

type Props = {
  pages: Pagination_pages$key;
  loadPage: (page: number, pageSize: number) => void;
  enablePageSizeControl?: boolean;
};

const Pagination = ({ pages, loadPage, enablePageSizeControl = true }: Props) => {
  const { first, around, last, next, previous } = useFragment(Pagination_pages, pages);
  const { pageSizeOptions, pageSize, setPageSize } = usePaginationContext();
  const { toast } = useToast();

  const inputRef = useRef<HTMLInputElement>(null);

  const jumpToPage = (page: number) => {
    if (page < 1 || (last && last.page < page)) {
      toast('잘못된 페이지 번호예요', 'error');
      return;
    }

    loadPage(page, pageSize);
  };
  const resetInput = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <Stack gapX={2}>
      <Stack.Item>
        <PaginationButton disabled={!previous} onClick={() => (previous ? loadPage(previous.page, pageSize) : null)}>
          <ChevronLeftIcon />
        </PaginationButton>
      </Stack.Item>
      {first ? (
        <>
          <Stack.Item>
            <PaginationButton onClick={() => loadPage(first.page, pageSize)}>
              <PaginationButtonText>{first.page}</PaginationButtonText>
            </PaginationButton>
          </Stack.Item>
          <Stack.Item>
            <StyledOcticon icon={KebabHorizontalIcon} color={'neutral.muted'} />
          </Stack.Item>
        </>
      ) : null}
      <ItemList
        items={around}
        renderItem={({ page, isCurrent }) => (
          <PaginationButton onClick={() => loadPage(page, pageSize)} isCurrent={isCurrent} disabled={isCurrent}>
            <PaginationButtonText>{page}</PaginationButtonText>
          </PaginationButton>
        )}
        renderItemWrapper={(children, { page }) => <Stack.Item key={page}>{children}</Stack.Item>}
      />
      {last ? (
        <>
          <Stack.Item>
            <StyledOcticon icon={KebabHorizontalIcon} color={'neutral.muted'} />
          </Stack.Item>
          <Stack.Item>
            <PaginationButton onClick={() => loadPage(last.page, pageSize)}>
              <PaginationButtonText>{last.page}</PaginationButtonText>
            </PaginationButton>
          </Stack.Item>
        </>
      ) : null}
      <Stack.Item>
        <PaginationButton disabled={!next} onClick={() => (next ? loadPage(next.page, pageSize) : null)}>
          <ChevronRightIcon />
        </PaginationButton>
      </Stack.Item>
      {enablePageSizeControl ? (
        <Stack.Item>
          <Select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              loadPage(1, Number(e.target.value));
            }}
          >
            {pageSizeOptions.map((value) => (
              <option key={value} value={value}>
                {value}개씩 보기
              </option>
            ))}
          </Select>
        </Stack.Item>
      ) : null}
      <Stack.Item>
        <NumberInput
          ref={inputRef}
          width={180}
          trailingVisual={'페이지로 가기'}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && inputRef.current) {
              jumpToPage(inputRef.current.valueAsNumber);
              resetInput();
            }
          }}
          trailingAction={
            <TextInput.Action
              icon={ArrowRightIcon}
              aria-label={'특정 페이지로 가기'}
              sx={{ color: 'fg.muted' }}
              onClick={() => {
                if (inputRef.current) jumpToPage(inputRef.current.valueAsNumber);
                resetInput();
              }}
            />
          }
        />
      </Stack.Item>
    </Stack>
  );
};

const PaginationButton = styled.button<{ isCurrent?: boolean }>`
  position: relative;
  border-radius: 50%;
  border-width: 0;
  background-color: ${({ isCurrent }) => (isCurrent ? themeGet('colors.neutral.muted') : 'transparent')};
  color: ${themeGet('colors.fg.muted')};
  width: ${themeGet('space.5')};
  height: ${themeGet('space.5')};

  :hover:not(:disabled) {
    background-color: ${themeGet('colors.neutral.subtle')};
    cursor: pointer;
  }
  :active:not(:disabled) {
    background-color: ${themeGet('colors.neutral.muted')};
  }
  :disabled {
    color: ${({ isCurrent }) => (isCurrent ? 'none' : themeGet('colors.neutral.muted'))};
  }
`;

const PaginationButtonText = styled(Text)`
  position: absolute;
  transform: translate(-50%, -50%);

  font-weight: ${themeGet('fontWeights.normal')};
  font-size: ${themeGet('fontSizes.1')};
  font-family: ${themeGet('fonts.normal')};
`;

export default Pagination;
export type { Props as PaginationProps };
