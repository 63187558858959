import { LogIcon, SearchIcon, SyncIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import React, { ComponentProps, Suspense } from 'react';
import { graphql } from 'react-relay';

import Button from '../../components/core/Button';
import DialogButton from '../../components/core/DialogButton';
import EmptyState from '../../components/core/EmptyState';
import EnumPairSearchOverlayTokenField from '../../components/core/EnumPairSearchOverlayTokenField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import FormulaSetConnectionDataTable from '../../components/formulaSet/FormulaSetConnectionDataTable';
import FormulaSetImageExportDialog from '../../components/formulaSet/FormulaSetImageExportDialog';
import FormulaSetWindowPaginator from '../../components/formulaSet/FormulaSetWindowPaginator';
import UnitAPaginatorSearchOverlayTokenField from '../../components/unitA/UnitAPaginatorSearchOverlayTokenField';
import UnitBPaginatorSearchOverlayTokenField from '../../components/unitB/UnitBPaginatorSearchOverlayTokenField';
import UnitCPaginatorSearchOverlayTokenField from '../../components/unitC/UnitCPaginatorSearchOverlayTokenField';
import UnitDPaginatorSearchOverlayTokenField from '../../components/unitD/UnitDPaginatorSearchOverlayTokenField';
import UserPaginatorSearchOverlayTokenField from '../../components/user/UserPaginatorSearchOverlayTokenField';
import { usePaginationContext } from '../../contexts/PaginationContext';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import {
  formulaSet_formulaSetsQuery,
  FormulaSetStatusEnum,
} from '../../relay/__generated__/formulaSet_formulaSetsQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { NextPage } from '../_app';

import FormulaSetId from './[formulaSetId]';

const formulaSetsForFormulaSet = graphql`
  query formulaSet_formulaSetsQuery($filters: FormulaSetFilter, $order: FormulaSetOrder, $page: Int, $pageSize: Int) {
    ...FormulaSetWindowPaginator_query @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

const FormulaSet: NextPage = () => {
  const router = useRouter();
  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    status_In: { type: 'string', multiple: true },
    unitAId_In: { type: 'string', multiple: true },
    unitBId_In: { type: 'string', multiple: true },
    unitCId_In: { type: 'string', multiple: true },
    unitDId_In: { type: 'string', multiple: true },
    reviewedById_In: { type: 'string', multiple: true },
    modifiedById_In: { type: 'string', multiple: true },
    order: { type: 'string' },
    page: { type: 'number' },
  });

  const {
    search,
    status_In,
    unitAId_In,
    unitBId_In,
    unitCId_In,
    unitDId_In,
    modifiedById_In,
    reviewedById_In,
    order,
    page,
  } = initialValues;

  const { pageSize } = usePaginationContext();

  return (
    <View>
      <Head title={'TCMS'} siteTitle={'개념집'} />
      <QueryFormik<formulaSet_formulaSetsQuery>
        query={formulaSetsForFormulaSet}
        staticVariables={{ pageSize }}
        initialValues={{
          filters: {
            search,
            status_In: status_In as FormulaSetStatusEnum[],
            unitAId_In,
            unitBId_In,
            unitCId_In,
            unitDId_In,
            modifiedById_In,
            reviewedById_In,
          },
          order: order || 'unitD_Order',
          page,
        }}
        options={{ fetchPolicy: 'store-and-network' }}
        onSubmit={(values) => setParsedUrlQuery({ ...values.filters, order }, { scroll: false })}
        enableReinitialize
      >
        {({ values: { filters, order }, setFieldValue, submitForm, resetForm }, queryReference) => {
          const handleReset = () => {
            resetForm({ values: { order } });
            setParsedUrlQuery({ order }, { scroll: false });
          };

          const handleChangeOrderActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
            newValue,
          ) => {
            setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
          };
          return (
            <View>
              <Grid sx={{ alignItems: 'center' }}>
                <Grid.Unit size={'max'}>
                  <Text as={'h1'}>개념집</Text>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <Stack gapX={2}>
                    <Stack.Item>
                      <DialogButton
                        size={'large'}
                        renderDialog={({ isOpen, closeDialog }) => (
                          <FormulaSetImageExportDialog isOpen={isOpen} onDismiss={closeDialog} wide />
                        )}
                      >
                        이미지 추출하기
                      </DialogButton>
                    </Stack.Item>
                    {/*<Stack.Item>*/}
                    {/*  <DialogButton*/}
                    {/*    size={'large'}*/}
                    {/*    renderDialog={({ isOpen, closeDialog }) => (*/}
                    {/*      <FormulaSetImageCsvExportDialog*/}
                    {/*        isOpen={isOpen}*/}
                    {/*        onDismiss={closeDialog}*/}
                    {/*        full*/}
                    {/*        variables={{ filters }}*/}
                    {/*      />*/}
                    {/*    )}*/}
                    {/*  >*/}
                    {/*    이미지 리스트 csv 추출하기*/}
                    {/*  </DialogButton>*/}
                    {/*</Stack.Item>*/}
                  </Stack>
                </Grid.Unit>
              </Grid>
              <Grid>
                <Grid.Unit size={'min'}>
                  <QueryFormik.FilterSearchTextField
                    typename={'FormulaSetFilter'}
                    label={'Search'}
                    labelConfig={{ visuallyHidden: true }}
                    name={'filters.search'}
                    size={'large'}
                    autoComplete={'off'}
                    leadingVisual={SearchIcon}
                    debounce
                    onChange={(e) => {
                      setFieldValue('filters.search', e.target.value);
                      setTimeout(() => submitForm(), 0);
                    }}
                  />
                </Grid.Unit>
                <Grid.Unit size={'max'}>
                  <View sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Stack gapX={2}>
                      <Stack.Item>
                        <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleReset}>
                          초기화
                        </Button>
                      </Stack.Item>
                      <Stack.Item>
                        <QueryFormik.OrderActionMenuButton
                          typename={'FormulaSetOrder'}
                          orders={['unitD_Order', 'modified']}
                          onChange={handleChangeOrderActionMenu}
                        />
                      </Stack.Item>
                    </Stack>
                  </View>
                </Grid.Unit>
              </Grid>
              <View sx={{ marginTop: 5 }}>
                <QueryFormik.FilterGrid
                  onChange={(filters) => {
                    setParsedUrlQuery({ ...filters, order }, { scroll: false });
                  }}
                >
                  <QueryFormik.FilterGridUnit size={1} title={'상태'}>
                    <EnumPairSearchOverlayTokenField
                      typename={'FormulaSetStatusEnum'}
                      label={'상태'}
                      name={'filters.status_In'}
                      placeholder={'상태 선택'}
                    />
                  </QueryFormik.FilterGridUnit>
                  <QueryFormik.FilterGridUnit size={1} title={'Unit'}>
                    <UnitAPaginatorSearchOverlayTokenField
                      label={'Unit A'}
                      name={'filters.unitAId_In'}
                      placeholder={'Unit A 선택'}
                      variables={{
                        order: { order: 'ASC' },
                      }}
                    />
                    <UnitBPaginatorSearchOverlayTokenField
                      label={'Unit B'}
                      name={'filters.unitBId_In'}
                      placeholder={'Unit B 선택'}
                      variables={{
                        order: { order: 'ASC' },
                        filters: {
                          unitAId_In: filters?.unitAId_In,
                        },
                      }}
                    />
                    <UnitCPaginatorSearchOverlayTokenField
                      label={'Unit C'}
                      name={'filters.unitCId_In'}
                      placeholder={'Unit C 선택'}
                      variables={{
                        order: { order: 'ASC' },
                        filters: {
                          unitAId_In: filters?.unitAId_In,
                          unitBId_In: filters?.unitBId_In,
                        },
                      }}
                    />
                    <UnitDPaginatorSearchOverlayTokenField
                      label={'Unit D'}
                      name={'filters.unitDId_In'}
                      placeholder={'Unit D 선택'}
                      variables={{
                        order: { order: 'ASC' },
                        filters: {
                          unitAId_In: filters?.unitAId_In,
                          unitBId_In: filters?.unitBId_In,
                          unitCId_In: filters?.unitCId_In,
                        },
                      }}
                    />
                  </QueryFormik.FilterGridUnit>
                  <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'마지막 작성자'}>
                    <UserPaginatorSearchOverlayTokenField
                      label={'마지막 작성자'}
                      name={'filters.modifiedById_In'}
                      placeholder={'마지막 작성자 선택'}
                    />
                  </QueryFormik.FilterGridUnit>
                  <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'검수자'}>
                    <UserPaginatorSearchOverlayTokenField
                      label={'검수자'}
                      name={'filters.reviewedById_In'}
                      placeholder={'검수자 선택'}
                    />
                  </QueryFormik.FilterGridUnit>
                </QueryFormik.FilterGrid>
              </View>
              <View sx={{ marginTop: 5 }}>
                <ErrorBoundary key={queryReference?.fetchKey}>
                  <Suspense fallback={<Spinner />}>
                    <QueryFormik.PreloadedQueryRenderer<formulaSet_formulaSetsQuery>>
                      {(queryReference) => (
                        <FormulaSetWindowPaginator
                          fragmentReference={queryReference}
                          onLoadPage={(page) => setParsedUrlQuery({ ...filters, order, page })}
                        >
                          {({ formulaSets }, { renderPagination }) => (
                            <>
                              <View>
                                <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                  총 {numberWithCommas(formulaSets.totalCount || 0)}개
                                </Text>
                              </View>
                              <View sx={{ marginTop: 3 }}>
                                <FormulaSetConnectionDataTable
                                  formulaSetConnection={formulaSets}
                                  onRowClick={({ id }) => router.push({ pathname: `/formulaSet/${id}` })}
                                  emptyState={
                                    <View sx={{ paddingY: 3 }}>
                                      <EmptyState
                                        title={'필터에 맞는 결과가 없어요'}
                                        description={'다른 필터로 다시 시도해보세요.'}
                                      />
                                    </View>
                                  }
                                />
                              </View>
                              <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                {renderPagination?.()}
                              </View>
                            </>
                          )}
                        </FormulaSetWindowPaginator>
                      )}
                    </QueryFormik.PreloadedQueryRenderer>
                  </Suspense>
                </ErrorBoundary>
              </View>
            </View>
          );
        }}
      </QueryFormik>
    </View>
  );
};

FormulaSet.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
FormulaSet.authenticated = true;
FormulaSet.routes = [
  { id: 'formulaSet', pathname: '/formulaSet', name: '개념집', icon: LogIcon, permissions: ['formula_set_read'] },
  ...FormulaSetId.routes,
];

export default FormulaSet;
