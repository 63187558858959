import { GearIcon, SearchIcon, SyncIcon, XIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { ComponentProps, Suspense } from 'react';
import { graphql } from 'react-relay';

import Button from '../../components/core/Button';
import DateRangeField from '../../components/core/DateRangeField';
import DialogButton from '../../components/core/DialogButton';
import EmptyState from '../../components/core/EmptyState';
import EnumPairSearchOverlayTokenField from '../../components/core/EnumPairSearchOverlayTokenField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import FetchQueryButton from '../../components/core/FetchQueryButton';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import IconButton from '../../components/core/IconButton';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import DeliveryOrderConnectionDataTable from '../../components/deliveryOrder/DeliveryOrderConnectionDataTable';
import DeliveryOrderCreateDialog from '../../components/deliveryOrder/DeliveryOrderCreateDialog';
import DeliveryOrderWindowPaginator from '../../components/deliveryOrder/DeliveryOrderWindowPaginator';
import DeliveryOrderXlsxImportDialog from '../../components/deliveryOrder/DeliveryOrderXlsxImportDialog';
import StuffPaginatorSearchOverlayTokenField from '../../components/stuff/StuffPaginatorSearchOverlayTokenField';
import UserPaginatorSearchOverlayTokenField from '../../components/user/UserPaginatorSearchOverlayTokenField';
import { usePaginationContext } from '../../contexts/PaginationContext';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useToast from '../../hooks/useToast';
import { deliveryOrder_deliveryOrderXlsxExportQuery } from '../../relay/__generated__/deliveryOrder_deliveryOrderXlsxExportQuery.graphql';
import {
  deliveryOrder_deliveryOrdersQuery,
  DeliveryOrderStatusEnum,
  DeliveryOrderTypeEnum,
} from '../../relay/__generated__/deliveryOrder_deliveryOrdersQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { NextPage } from '../_app';

import DeliveryOrderId from './[deliveryOrderId]';

const deliveryOrdersForDeliveryOrder = graphql`
  query deliveryOrder_deliveryOrdersQuery(
    $filters: DeliveryOrderFilter
    $order: DeliveryOrderOrder
    $page: Int
    $pageSize: Int
  ) {
    ...DeliveryOrderWindowPaginator_query @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

type Props = {};

const DeliveryOrder: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();
  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    order: { type: 'string' },
    page: { type: 'number' },
    status_In: { type: 'string', multiple: true },
    createdById_In: { type: 'string', multiple: true },
    deliveredById_In: { type: 'string', multiple: true },
    type_In: { type: 'string', multiple: true },
    stuffId_In: { type: 'string', multiple: true },
    created_Gte: { type: 'string' },
    created_Lte: { type: 'string' },
    delivered_Gte: { type: 'string' },
    delivered_Lte: { type: 'string' },
  });
  const {
    search,
    order,
    page,
    status_In,
    createdById_In,
    deliveredById_In,
    stuffId_In,
    type_In,
    created_Gte,
    created_Lte,
    delivered_Gte,
    delivered_Lte,
  } = initialValues;

  const { pageSize } = usePaginationContext();

  return (
    <View>
      <Head siteTitle={'배송내역'} />
      <ErrorBoundary>
        <QueryFormik<deliveryOrder_deliveryOrdersQuery>
          query={deliveryOrdersForDeliveryOrder}
          staticVariables={{ pageSize }}
          initialValues={{
            filters: {
              search,
              status_In: status_In as DeliveryOrderStatusEnum[],
              createdById_In,
              deliveredById_In,
              stuffId_In,
              type_In: type_In as DeliveryOrderTypeEnum[],
              created_Gte,
              created_Lte,
              delivered_Gte,
              delivered_Lte,
            },
            order: order || '-created',
            page,
          }}
          options={{ fetchPolicy: 'store-and-network' }}
          onSubmit={(values) => setParsedUrlQuery({ ...values.filters, order }, { scroll: false })}
          enableReinitialize
        >
          {({ values: { filters, order }, handleSubmit, resetForm, setFieldValue, submitForm }, queryReference) => {
            const handleReset = () => {
              resetForm({ values: { order } });
              setParsedUrlQuery({ order }, { scroll: false });
            };

            const handleChangeOrderActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
              newValue,
            ) => {
              setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
            };

            const isSelectedCreatedPeriod = filters?.created_Lte || filters?.created_Gte;
            const isSelectedDeliveredPeriod = filters?.delivered_Lte || filters?.delivered_Gte;

            return (
              <View>
                <Grid sx={{ alignItems: 'center' }}>
                  <Grid.Unit size={'max'}>
                    <Text as={'h1'}>배송내역</Text>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <Stack gapX={2}>
                      <Stack.Item>
                        <FetchQueryButton<deliveryOrder_deliveryOrderXlsxExportQuery>
                          query={graphql`
                            query deliveryOrder_deliveryOrderXlsxExportQuery($filters: DeliveryOrderFilter) {
                              deliveryOrderXlsxExport(filters: $filters)
                            }
                          `}
                          size={'large'}
                          variables={{ filters }}
                          variant={'default'}
                          onComplete={(data) => {
                            if (data) router.push(data.deliveryOrderXlsxExport);
                          }}
                          onError={() => toast('xlsx 다운로드에 실패했어요', 'error')}
                        >
                          xlsx 다운로드
                        </FetchQueryButton>
                      </Stack.Item>
                      <Stack.Item>
                        <DialogButton
                          size={'large'}
                          variant={'default'}
                          renderDialog={({ isOpen, closeDialog }) => (
                            <DeliveryOrderXlsxImportDialog
                              isOpen={isOpen}
                              onDismiss={closeDialog}
                              wide
                              config={{
                                onCompleted: (data) => {
                                  toast(`${data.deliveryOrderXlsxImport.length}개의 운송장이 등록되었어요!`, 'success');
                                  closeDialog();
                                },
                              }}
                            />
                          )}
                        >
                          xlsx 업로드
                        </DialogButton>
                      </Stack.Item>
                      <Stack.Item>
                        <DialogButton
                          size={'large'}
                          renderDialog={({ isOpen, closeDialog }) => (
                            <DeliveryOrderCreateDialog
                              isOpen={isOpen}
                              onDismiss={() => closeDialog()}
                              wide
                              config={{
                                onCompleted: () => {
                                  toast('배송 내역 생성 완료!', 'success');
                                  handleSubmit();
                                  closeDialog();
                                },
                              }}
                            />
                          )}
                          variant={'primary'}
                        >
                          생성하기
                        </DialogButton>
                      </Stack.Item>
                    </Stack>
                  </Grid.Unit>
                </Grid>
                <Grid>
                  <Grid.Unit size={'min'}>
                    <QueryFormik.FilterSearchTextField
                      typename={'DeliveryOrderFilter'}
                      label={'Search'}
                      labelConfig={{ visuallyHidden: true }}
                      name={'filters.search'}
                      size={'large'}
                      autoComplete={'off'}
                      leadingVisual={SearchIcon}
                      debounce
                      onChange={(e) => {
                        setFieldValue('filters.search', e.target.value);
                        setTimeout(() => submitForm(), 0);
                      }}
                    />
                  </Grid.Unit>
                  <Grid.Unit size={'max'}>
                    <View sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Stack gapX={2}>
                        <Stack.Item>
                          <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleReset}>
                            초기화
                          </Button>
                        </Stack.Item>
                        <Stack.Item>
                          <QueryFormik.OrderActionMenuButton
                            typename={'DeliveryOrderOrder'}
                            orders={['delivered', 'created']}
                            onChange={handleChangeOrderActionMenu}
                          />
                        </Stack.Item>
                      </Stack>
                    </View>
                  </Grid.Unit>
                </Grid>
                <View sx={{ marginTop: 5 }}>
                  <QueryFormik.FilterGrid
                    onChange={(filters) => {
                      setParsedUrlQuery({ ...filters, order }, { scroll: false });
                    }}
                  >
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'상태'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'DeliveryOrderStatusEnum'}
                        label={'상태'}
                        name={'filters.status_In'}
                        placeholder={'상태 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'물품'}>
                      <StuffPaginatorSearchOverlayTokenField
                        label={'물품'}
                        placeholder={'물품 선택'}
                        name={'filters.stuffId_In'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={1} title={'타입'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'DeliveryOrderTypeEnum'}
                        label={'타입'}
                        placeholder={'타입 선택'}
                        name={'filters.type_In'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'요청자'}>
                      <UserPaginatorSearchOverlayTokenField
                        label={'요청자'}
                        name={'filters.createdById_In'}
                        placeholder={'요청자 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'요청일'}>
                      <Grid sx={{ alignItems: 'center' }} wrap={false}>
                        <Grid.Unit size={'max'}>
                          <DateRangeField
                            label={'요청일'}
                            labelConfig={{ visuallyHidden: true }}
                            name={['filters.created_Gte', 'filters.created_Lte']}
                            placeholder={['시작일', '종료일']}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={'min'}>
                          <IconButton
                            icon={XIcon}
                            aria-label={'reset created period'}
                            variant={'plain'}
                            disabled={!isSelectedCreatedPeriod}
                            onClick={() => {
                              setFieldValue('filters', { ...filters, created_Gte: undefined, created_Lte: undefined });
                            }}
                          />
                        </Grid.Unit>
                      </Grid>
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'배송자'}>
                      <UserPaginatorSearchOverlayTokenField
                        label={'배송자'}
                        name={'filters.deliveredById_In'}
                        placeholder={'배송자 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'배송일'}>
                      <Grid sx={{ alignItems: 'center' }} wrap={false}>
                        <Grid.Unit size={'max'}>
                          <DateRangeField
                            label={'배송일'}
                            labelConfig={{ visuallyHidden: true }}
                            name={['filters.delivered_Gte', 'filters.delivered_Lte']}
                            placeholder={['시작일', '종료일']}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={'min'}>
                          <IconButton
                            icon={XIcon}
                            aria-label={'reset delivered period'}
                            variant={'plain'}
                            disabled={!isSelectedDeliveredPeriod}
                            onClick={() => {
                              setFieldValue('filters', {
                                ...filters,
                                delivered_Gte: undefined,
                                delivered_Lte: undefined,
                              });
                            }}
                          />
                        </Grid.Unit>
                      </Grid>
                    </QueryFormik.FilterGridUnit>
                  </QueryFormik.FilterGrid>
                </View>
                <View sx={{ marginTop: 5 }}>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<deliveryOrder_deliveryOrdersQuery>>
                        {(queryReference) => (
                          <DeliveryOrderWindowPaginator
                            fragmentReference={queryReference}
                            onLoadPage={(page) => setParsedUrlQuery({ ...filters, order, page })}
                          >
                            {({ deliveryOrders }, { renderPagination }) => (
                              <>
                                <View>
                                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                    총 {numberWithCommas(deliveryOrders.totalCount || 0)}개
                                  </Text>
                                </View>
                                <View sx={{ marginTop: 3 }}>
                                  <DeliveryOrderConnectionDataTable
                                    deliveryOrderConnection={deliveryOrders}
                                    onRowClick={({ id }) => router.push({ pathname: `/deliveryOrder/${id}` })}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState
                                          title={'필터에 맞는 결과가 없어요'}
                                          description={'다른 필터로 다시 시도해보세요.'}
                                        />
                                      </View>
                                    }
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.()}
                                </View>
                              </>
                            )}
                          </DeliveryOrderWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            );
          }}
        </QueryFormik>
      </ErrorBoundary>
    </View>
  );
};

DeliveryOrder.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
DeliveryOrder.authenticated = true;
DeliveryOrder.routes = [
  {
    id: 'deliveryOrder',
    icon: GearIcon,
    pathname: '/deliveryOrder',
    name: '배송내역',
    permissions: ['delivery_order_read'],
  },
  ...DeliveryOrderId.routes,
];

export default DeliveryOrder;
