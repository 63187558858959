import { PasteIcon, SearchIcon, SyncIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { ComponentProps, Suspense } from 'react';
import { graphql } from 'react-relay';

import Button from '../../components/core/Button';
import DateField from '../../components/core/DateField';
import DialogButton from '../../components/core/DialogButton';
import EmptyState from '../../components/core/EmptyState';
import EnumPairSearchOverlayTokenField from '../../components/core/EnumPairSearchOverlayTokenField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import StoreStuffConnectionDataTable from '../../components/storeStuff/StoreStuffConnectionDataTable';
import StoreStuffCreateDialog from '../../components/storeStuff/StoreStuffCreateDialog';
import StoreStuffPeriodsSearchOverlayTokenField from '../../components/storeStuff/StoreStuffPeriodsSearchOverlayTokenField';
import StoreStuffWindowPaginator from '../../components/storeStuff/StoreStuffWindowPaginator';
import UserPaginatorSearchOverlayTokenField from '../../components/user/UserPaginatorSearchOverlayTokenField';
import { usePaginationContext } from '../../contexts/PaginationContext';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useToast from '../../hooks/useToast';
import {
  StoreStuffTypeEnum,
  storeStuff_storeStuffsQuery,
} from '../../relay/__generated__/storeStuff_storeStuffsQuery.graphql';
import { endOfDay, formatISO } from '../../utils/date';
import { numberWithCommas } from '../../utils/number';
import { NextPage } from '../_app';

import StoreStuffId from './[storeStuffId]';

const storeStuffsForStoreStuff = graphql`
  query storeStuff_storeStuffsQuery($filters: StoreStuffFilter, $order: StoreStuffOrder, $page: Int, $pageSize: Int) {
    ...StoreStuffWindowPaginator_query @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

type Props = {};

const StoreStuff: NextPage<Props> = () => {
  const router = useRouter();

  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    page: { type: 'number' },
    order: { type: 'string' },

    createdById_In: { type: 'string', multiple: true },
    type_In: { type: 'string', multiple: true },
    endAt_Exact: { type: 'string' },
    period_In: { type: 'string', multiple: true },
  });
  const { search, createdById_In, type_In, endAt_Exact, period_In, page, order } = initialValues;

  const { pageSize } = usePaginationContext();

  const { toast } = useToast();

  return (
    <View>
      <Head siteTitle={'상품 (아이템)'} />
      <ErrorBoundary>
        <QueryFormik<storeStuff_storeStuffsQuery>
          query={storeStuffsForStoreStuff}
          staticVariables={{ pageSize }}
          initialValues={{
            filters: { search, createdById_In, type_In: type_In as StoreStuffTypeEnum[], endAt_Exact, period_In },
            order: order || '-modified',
            page,
          }}
          options={{ fetchPolicy: 'store-and-network' }}
          onSubmit={(values) => setParsedUrlQuery({ ...values.filters, order }, { scroll: false })}
          enableReinitialize
        >
          {({ values: { filters, order }, setFieldValue, handleSubmit, submitForm, resetForm }, queryReference) => {
            const handleReset = () => {
              resetForm({ values: { order } });
              setParsedUrlQuery({ order }, { scroll: false });
            };

            const handleChangeOrderActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
              newValue,
            ) => {
              setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
            };

            return (
              <View>
                <Grid sx={{ alignItems: 'center' }}>
                  <Grid.Unit size={'max'}>
                    <Text as={'h1'}>상품 (아이템)</Text>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <DialogButton
                      size={'large'}
                      variant={'primary'}
                      renderDialog={({ isOpen, closeDialog }) => (
                        <StoreStuffCreateDialog
                          isOpen={isOpen}
                          onDismiss={closeDialog}
                          wide
                          config={{
                            onCompleted: () => {
                              toast('상품 생성이 완료됐어요!', 'success');
                              handleSubmit();
                              closeDialog();
                            },
                            onError: () => {
                              toast('다시 생성해 주세요', 'error');
                            },
                          }}
                        />
                      )}
                    >
                      생성하기
                    </DialogButton>
                  </Grid.Unit>
                </Grid>
                <Grid>
                  <Grid.Unit size={'min'}>
                    <QueryFormik.FilterSearchTextField
                      typename={'StoreStuffFilter'}
                      label={'Search'}
                      labelConfig={{ visuallyHidden: true }}
                      name={'filters.search'}
                      size={'large'}
                      autoComplete={'off'}
                      leadingVisual={SearchIcon}
                      debounce
                      onChange={(e) => {
                        setFieldValue('filters.search', e.target.value);
                        setTimeout(() => submitForm(), 0);
                      }}
                    />
                  </Grid.Unit>
                  <Grid.Unit size={'max'}>
                    <View sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Stack gapX={2}>
                        <Stack.Item>
                          <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleReset}>
                            초기화
                          </Button>
                        </Stack.Item>
                        <Stack.Item>
                          <QueryFormik.OrderActionMenuButton
                            typename={'StoreStuffOrder'}
                            orders={['modified']}
                            onChange={handleChangeOrderActionMenu}
                          />
                        </Stack.Item>
                      </Stack>
                    </View>
                  </Grid.Unit>
                </Grid>
                <View sx={{ marginTop: 5 }}>
                  <QueryFormik.FilterGrid
                    onChange={(filters) => {
                      setParsedUrlQuery({ ...filters, order }, { scroll: false });
                    }}
                  >
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'타입'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'StoreStuffTypeEnum'}
                        label={'타입'}
                        name={'filters.type_In'}
                        placeholder={'타입 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'제작자'}>
                      <UserPaginatorSearchOverlayTokenField
                        label={'제작자'}
                        name={'filters.createdById_In'}
                        placeholder={'제작자 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'기간'}>
                      <StoreStuffPeriodsSearchOverlayTokenField
                        label={'기간'}
                        name={'filters.period_In'}
                        placeholder={'기간 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'만료일'}>
                      <DateField
                        label={'만료일'}
                        placeholder={'만료일 선택'}
                        name={'filters.endAt_Exact'}
                        onChange={(date) => setFieldValue('filters.endAt_Exact', formatISO(endOfDay(date)))}
                      />
                    </QueryFormik.FilterGridUnit>
                  </QueryFormik.FilterGrid>
                </View>
                <View sx={{ marginTop: 5 }}>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<storeStuff_storeStuffsQuery>>
                        {(queryReference) => (
                          <StoreStuffWindowPaginator
                            fragmentReference={queryReference}
                            onLoadPage={(page) => setParsedUrlQuery({ ...filters, order, page })}
                          >
                            {({ storeStuffs }, { renderPagination }) => (
                              <>
                                <View>
                                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                    총 {numberWithCommas(storeStuffs?.totalCount || 0)}개
                                  </Text>
                                </View>
                                <View sx={{ marginTop: 3 }}>
                                  <StoreStuffConnectionDataTable
                                    storeStuffConnection={storeStuffs}
                                    onRowClick={({ id }) => router.push({ pathname: `/storeStuff/${id}` })}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState
                                          title={'필터에 맞는 결과가 없어요'}
                                          description={'다른 필터로 다시 시도해보세요.'}
                                        />
                                      </View>
                                    }
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.()}
                                </View>
                              </>
                            )}
                          </StoreStuffWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            );
          }}
        </QueryFormik>
      </ErrorBoundary>
    </View>
  );
};

StoreStuff.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
StoreStuff.authenticated = true;
StoreStuff.routes = [
  {
    id: 'storeStuff',
    name: '상품 (아이템)',
    pathname: '/storeStuff',
    icon: PasteIcon,
    permissions: ['store_stuff_read'],
  },
  ...StoreStuffId.routes,
];

export default StoreStuff;
