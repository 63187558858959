import { isFunction } from 'lodash-es';

import useSessionStorage from '../../hooks/useSessionStorage';
import createCtx from '../../utils/context';

type PaginationContextValue = {
  pageSizeOptions: number[];

  pageSize: number;
  setPageSize: (pageSize: number) => void;
};

const [useContext, BaseProvider] = createCtx<PaginationContextValue>();

type PaginationContextProviderProps = {
  children: ((values: PaginationContextValue) => React.ReactNode) | React.ReactNode;
};
const PaginationContextProvider = ({ children }: PaginationContextProviderProps) => {
  const pageSizeOptions = [20, 50, 100];
  const [_pageSize, _setPageSize] = useSessionStorage<string>(
    'PaginationContextProvider_pageSize',
    pageSizeOptions[0].toString(),
  );

  const setPageSize = (pageSize: number) => {
    _setPageSize(`${pageSize}`);
  };

  const value: PaginationContextValue = { pageSizeOptions, pageSize: Number(_pageSize), setPageSize };
  return <BaseProvider value={value}>{isFunction(children) ? children(value) : children}</BaseProvider>;
};

export { useContext as usePaginationContext, PaginationContextProvider };
