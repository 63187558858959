import {
  AlertIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  KebabHorizontalIcon,
  PencilIcon,
  PlusIcon,
  ShareIcon,
  StopIcon,
  TrashIcon,
  XIcon,
} from '@primer/octicons-react';
import { Space } from '@teamturing/react-kit';
import { useRouter } from 'next/router';
import React, { Suspense, useRef } from 'react';
import { graphql } from 'react-relay';

import CommentCreateMutationFormik from '../../components/comment/CommentCreateMutationFormik';
import CommentMutationActionItem from '../../components/comment/CommentMutationActionItem';
import CommentPaginator from '../../components/comment/CommentPaginator';
import ActionList from '../../components/core/ActionList';
import ActionMenu from '../../components/core/ActionMenu';
import Button from '../../components/core/Button';
import Card from '../../components/core/Card';
import { RowWrapper } from '../../components/core/DataTable';
import DateText from '../../components/core/DateText';
import DescriptionList from '../../components/core/DescriptionList';
import DialogButton from '../../components/core/DialogButton';
import DialogHandler from '../../components/core/DialogHandler';
import DialogIconButton from '../../components/core/DialogIconButton';
import EmptyState from '../../components/core/EmptyState';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import FixedMiniView from '../../components/core/FixedMiniView';
import Flash from '../../components/core/Flash';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import IconButton from '../../components/core/IconButton';
import Image from '../../components/core/Image';
import ItemList from '../../components/core/ItemList';
import Katex from '../../components/core/Katex';
import Label from '../../components/core/Label';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import MutationConfirmIconButton from '../../components/core/MutationConfirmIconButton';
import ScrollMiniKatex from '../../components/core/ScrollMiniKatex';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import StyledOcticon from '../../components/core/StyledOcticon';
import TabHandler from '../../components/core/TabHandler';
import Text from '../../components/core/Text';
import Timeline from '../../components/core/Timeline';
import Tooltip from '../../components/core/Tooltip';
import View from '../../components/core/View';
import ErrorReportItem from '../../components/errorReport/ErrorReportItem';
import ErrorReportPaginator from '../../components/errorReport/ErrorReportPaginator';
import TaskActionsMutationButtonStack from '../../components/task/TaskActionsMutationButtonStack';
import TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog from '../../components/task/TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog';
import TaskDemoDialog from '../../components/task/TaskDemoDialog';
import TaskDescriptionList from '../../components/task/TaskDescriptionList';
import TaskFinalLabelingDialog from '../../components/task/TaskFinalLabelingDialog';
import TaskFirstLabelingDialog from '../../components/task/TaskFirstLabelingDialog';
import TaskHintFeedbackSaveDialog from '../../components/task/TaskHintFeedbackSaveDialog';
import TaskHintStatusLabel from '../../components/task/TaskHintStatusLabel';
import TaskHistoryDialog from '../../components/task/TaskHistoryDialog';
import TaskHistoryTimelineItem from '../../components/task/TaskHistoryTimelineItem';
import TaskImageCreationUpdateDialog from '../../components/task/TaskImageCreationUpdateDialog';
import TaskLabelingProblemUpdateDialog from '../../components/task/TaskLabelingProblemUpdateDialog';
import TaskProblemSolveInfoDescriptionList from '../../components/task/TaskProblemSolveInfoDescriptionList';
import TaskProblemTypingDialog from '../../components/task/TaskProblemTypingDialog';
import TaskProblemUpdateDialog from '../../components/task/TaskProblemUpdateDialog';
import TaskSecondLabelingDialog from '../../components/task/TaskSecondLabelingDialog';
import TaskSolutionVideoCreateDialog from '../../components/task/TaskSolutionVideoCreateDialog';
import TaskSolutionVideoGrid from '../../components/task/TaskSolutionVideoGrid';
import TaskSolutionVideoUpdateDialog from '../../components/task/TaskSolutionVideoUpdateDialog';
import TaskSolvedProblemUserAnswerUpdateDialog from '../../components/task/TaskSolvedProblemUserAnswerUpdateDialog';
import TaskSubmitFeedbackStatusLabel from '../../components/task/TaskSubmitFeedbackStatusLabel';
import TaskTaskHistoryPaginator from '../../components/task/TaskTaskHistoryPaginator';
import TaskTypingAutomationHistoryCreateDialog from '../../components/task/TaskTypingAutomationHistoryCreateDialog';
import TaskTypingAutomationHistoryPaginator from '../../components/task/TaskTypingAutomationHistoryPaginator';
import TaskVideoConnectionDataTable from '../../components/task/TaskVideoConnectionDataTable';
import TaskVideoCreateDialog from '../../components/task/TaskVideoCreateDialog';
import TypingAutomationHistoryDialog from '../../components/typingAutomationHistory/TypingAutomationHistoryDialog';
import TypingAutomationHistoryTimelineItem from '../../components/typingAutomationHistory/TypingAutomationHistoryTimelineItem';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { Ordering, TaskId_commentsQuery } from '../../relay/__generated__/TaskId_commentsQuery.graphql';
import { TaskId_errorReportDeleteMutation } from '../../relay/__generated__/TaskId_errorReportDeleteMutation.graphql';
import { TaskId_errorReportsQuery } from '../../relay/__generated__/TaskId_errorReportsQuery.graphql';
import { TaskId_taskDeleteMutation } from '../../relay/__generated__/TaskId_taskDeleteMutation.graphql';
import { TaskFilter, TaskId_taskQuery } from '../../relay/__generated__/TaskId_taskQuery.graphql';
import { isNullable } from '../../utils/is';
import { numberWithCommas } from '../../utils/number';
import { parseOrdering } from '../../utils/order';
import { scrollToBottom } from '../../utils/scroll';
import { NextPage } from '../_app';

const taskForTaskId = graphql`
  query TaskId_taskQuery($id: ID!, $filters: TaskFilter, $order: TaskOrder) {
    task(id: $id) {
      id
      status
      title
      sequence
      previous(filters: $filters, order: $order)
      next(filters: $filters, order: $order)

      ...TaskDescriptionList_task

      showProblem
      showLabeling
      showVideo
      showSolutionVideo

      problem {
        solution
        problem
      }

      scrap {
        id
        solutions {
          id
          objectUrl
        }
        problems {
          id
          objectUrl
        }
      }

      answerChangeHistory {
        previousAnswer
      }

      actions
      videos {
        totalCount
        ...TaskVideoConnectionDataTable_taskVideoConnection
      }
      solutionVideo {
        id
        modified
        isPremium
        isRecommended
        taskVideo {
          id
        }
        ...TaskSolutionVideoUpdateDialog_taskSolutionVideo
        ...TaskSolutionVideoGrid_taskSolutionVideo
      }
      hint {
        id
        text
        publishedText
        ...TaskHintStatusLabel_taskHint
      }
      submitFeedback {
        id
        specialAnswer
        special
        general
        publishedGeneral
        publishedSpecial
        publishedSpecialAnswer
        ...TaskSubmitFeedbackStatusLabel_taskSubmitFeedback
      }
      problemSolveInfo {
        ...TaskProblemSolveInfoDescriptionList_taskProblemSolveInfo
        specialAnswers {
          rank
          answer
          percentage
        }
      }

      ...TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_task

      ...TaskSolvedProblemUserAnswerUpdateDialog_task
      ...TaskDemoDialog_task
      ...TaskActionsMutationButtonStack_task

      ...TaskProblemTypingDialog_task
      ...TaskProblemUpdateDialog_task
      ...TaskImageCreationUpdateDialog_task
      ...TaskLabelingProblemUpdateDialog_task
      ...TaskFirstLabelingDialog_task
      ...TaskSecondLabelingDialog_task
      ...TaskFinalLabelingDialog_task

      ...TaskHintFeedbackSaveDialog_task

      ...TaskTypingAutomationHistoryCreateDialog_task
      ...TaskTypingAutomationHistoryPaginator_task @arguments(order: { created: DESC })

      ...TaskTaskHistoryPaginator_task
    }
  }
`;

const commentsForTaskId = graphql`
  query TaskId_commentsQuery($filters: CommentFilter, $order: CommentOrder) {
    ...CommentPaginator_query @arguments(filters: $filters, order: $order)
  }
`;

const errorReportsForTaskId = graphql`
  query TaskId_errorReportsQuery($filters: ErrorReportFilter, $order: ErrorReportOrder, $first: Int) {
    ...ErrorReportPaginator_query @arguments(filters: $filters, order: $order, first: $first)
  }
`;

const TaskId: NextPage = () => {
  const router = useRouter();
  const { taskId } = router.query;
  const { toast } = useToast();

  const [errorReports] = useLazyLoadQuery<TaskId_errorReportsQuery>(errorReportsForTaskId, {
    filters: { nodeId_Exact: taskId as string },
    order: { created: 'DESC' },
    first: 3,
  });
  const [comments] = useLazyLoadQuery<TaskId_commentsQuery>(commentsForTaskId, {
    filters: { nodeId_Exact: taskId as string },
    order: { created: 'ASC' as Ordering },
  });

  const commentContainerRef = useRef<HTMLDivElement>(null);

  const problemTabItems = [
    { id: 'all', label: '창작 + 스크랩' },
    { id: 'creation', label: '창작' },
    { id: 'scrap', label: '스크랩' },
  ];

  const { initialValues } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    status_Exact: { type: 'string' },
    problemType_In: { type: 'string', multiple: true },
    ebook_In: { type: 'string', multiple: true },
    problemGenerator_Exists_In: { type: 'boolean', multiple: true },
    problemCreationType_In: { type: 'string', multiple: true },
    answerType_In: { type: 'string', multiple: true },
    image_Exists_In: { type: 'boolean', multiple: true },

    scrapSourceCategory_In: { type: 'string', multiple: true },
    scrapSourceGrade_In: { type: 'string', multiple: true },
    scrapSourceMonth_In: { type: 'string', multiple: true },
    scrapSourceSource_In: { type: 'string', multiple: true },
    scrapSourceYear_In: { type: 'string', multiple: true },
    scrapSourceBookName_Contains: { type: 'string' },
    scrapSourceUnitAId_In: { type: 'string', multiple: true },
    scrapSourceBookPublisher_Contains: { type: 'string' },

    unitAId_In: { type: 'string', multiple: true },
    unitBId_In: { type: 'string', multiple: true },
    unitCId_In: { type: 'string', multiple: true },
    unitDId_In: { type: 'string', multiple: true },
    lastUnitDId_In: { type: 'string', multiple: true },
    difficulty_Gte: { type: 'number' },
    difficulty_Lte: { type: 'number' },

    assignedTo_Exists_In: { type: 'boolean', multiple: true },
    assignedToId_In: { type: 'string', multiple: true },

    status_In: { type: 'string', multiple: true },
    completedById_In: { type: 'string', multiple: true },
    completed_Gte: { type: 'string' },
    completed_Lte: { type: 'string' },

    completedById_Exclude_In: { type: 'string', multiple: true },

    solutionVideo_Exists_In: { type: 'boolean', multiple: true },
    solutionVideoVideoType_In: { type: 'string', multiple: true },

    hint_Published_In: { type: 'boolean', multiple: true },
    specialSubmitFeedback_Published_In: { type: 'boolean', multiple: true },
    generalSubmitFeedback_Published_In: { type: 'boolean', multiple: true },

    errorType_In: { type: 'string', multiple: true },

    typingAutomationScript_Exists_In: { type: 'boolean', multiple: true },

    assignedToId_Exact: { type: 'string', multiple: false },
    courseId_Exact: { type: 'string', multiple: false },

    order: { type: 'string' },
  });

  const { order, ...filters } = initialValues;

  const [data, refresh] = useLazyLoadQuery<TaskId_taskQuery>(taskForTaskId, {
    id: taskId as string,
    filters: filters as TaskFilter,
    order: order ? parseOrdering(order as string) : undefined,
  });
  const { task } = data;
  if (!task) return null;
  const {
    id,
    status,
    title,
    sequence,
    previous: previousTaskId,
    next: nextTaskId,
    problem,
    scrap,
    videos,
    solutionVideo,
    actions,
    showProblem,
    showLabeling,
    showVideo,
    showSolutionVideo,
    hint,
    submitFeedback,
    problemSolveInfo,
  } = task;

  const hasPrevious = !isNullable(previousTaskId);
  const handlePreviousButtonClick = () => {
    const previousTaskPathname = `/task/${previousTaskId}`;
    router.replace({ pathname: previousTaskPathname, query: initialValues }, previousTaskPathname);
  };

  const hasNext = !isNullable(nextTaskId);
  const handleNextButtonClick = () => {
    const nextTaskPathname = `/task/${nextTaskId}`;
    router.replace({ pathname: nextTaskPathname, query: initialValues }, nextTaskPathname);
  };

  const isHintPublished = hint?.publishedText;
  const isSubmitFeedbackPublished =
    submitFeedback?.publishedGeneral || submitFeedback?.publishedSpecial || submitFeedback?.publishedSpecialAnswer;

  return (
    <View>
      <Head siteTitle={title} />
      <View>
        <Grid sx={{ alignItems: 'center' }} gapX={2} gapY={2}>
          <Grid.Unit size={[1, 1, 'max']}>
            <Stack gapX={3}>
              <Stack.Item>
                <IconButton
                  icon={ChevronLeftIcon}
                  aria-label={'Previous Task Id'}
                  disabled={!hasPrevious}
                  onClick={() => handlePreviousButtonClick()}
                />
              </Stack.Item>
              <Stack.Item>
                <Text as={'h1'}>{sequence}</Text>
              </Stack.Item>
              <Stack.Item>
                <IconButton
                  icon={ChevronRightIcon}
                  aria-label={'Next Task Id'}
                  disabled={!hasNext}
                  onClick={() => handleNextButtonClick()}
                />
              </Stack.Item>
            </Stack>
          </Grid.Unit>
          {status === 'published' ? (
            <>
              <Grid.Unit size={'min'}>
                <Tooltip text={'퍼가기'}>
                  <DialogIconButton
                    aria-labelledby={'퍼가기'}
                    icon={ShareIcon}
                    size={'large'}
                    renderDialog={({ isOpen, closeDialog }) => (
                      <TaskDemoDialog task={task} isOpen={isOpen} onDismiss={closeDialog} />
                    )}
                  />
                </Tooltip>
              </Grid.Unit>
              <Grid.Unit size={'min'}>
                <DialogButton
                  variant={'outline'}
                  size={'large'}
                  renderDialog={({ isOpen, closeDialog }) => (
                    <TaskSolvedProblemUserAnswerUpdateDialog
                      task={task}
                      isOpen={isOpen}
                      onDismiss={closeDialog}
                      config={{
                        onCompleted: () => {
                          closeDialog();
                          toast('정답 처리가 완료됐어요!', 'success');
                          refresh();
                        },
                      }}
                    />
                  )}
                >
                  정답 처리하기
                </DialogButton>
              </Grid.Unit>
            </>
          ) : null}
          <Grid.Unit size={'min'}>
            <TaskActionsMutationButtonStack task={task} />
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            {actions.includes('task_delete') ? (
              <ActionMenu>
                <ActionMenu.Anchor>
                  <IconButton icon={KebabHorizontalIcon} variant={'plain'} aria-label="Open column options" />
                </ActionMenu.Anchor>
                <ActionMenu.Overlay>
                  <ActionList>
                    <ActionList.MutationItem<TaskId_taskDeleteMutation>
                      mutation={graphql`
                        mutation TaskId_taskDeleteMutation($input: TaskDeleteInput!) {
                          taskDelete(input: $input) {
                            id @deleteRecord
                          }
                        }
                      `}
                      input={{ id }}
                      variant={'danger'}
                      config={{
                        onCompleted: () => {
                          router.replace('/task').then(() => toast('문제 삭제 완료!', 'success'));
                        },
                        onError: () => {
                          toast('문제 삭제에 실패했어요', 'error');
                        },
                      }}
                      message={'정말 삭제할까요?'}
                    >
                      <ActionList.LeadingVisual>
                        <TrashIcon />
                      </ActionList.LeadingVisual>
                      삭제하기
                    </ActionList.MutationItem>
                  </ActionList>
                </ActionMenu.Overlay>
              </ActionMenu>
            ) : null}
          </Grid.Unit>
        </Grid>
        <HorizontalDivider mt={[3, 3, 0]} mb={5} />
        {task.answerChangeHistory ? (
          <Flash variant={'danger'} sx={{ marginBottom: 5 }}>
            <Grid sx={{}}>
              <Grid.Unit size={'min'}>
                <StyledOcticon icon={AlertIcon} color={'fg.danger'} />
              </Grid.Unit>
              <Grid.Unit size={'max'}>
                <Text fontSize={1}>
                  답을 {task.answerChangeHistory.previousAnswer}으로 제출한 학생들의 정답 처리가 필요해요.
                </Text>
                <DialogButton
                  sx={{ mt: 2 }}
                  renderDialog={({ isOpen, closeDialog }) => (
                    <TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog
                      task={task}
                      isOpen={isOpen}
                      onDismiss={closeDialog}
                      config={{
                        onCompleted: () => {
                          closeDialog();
                          toast('정답 처리가 완료됐어요!', 'success');
                          refresh();
                        },
                      }}
                    />
                  )}
                >
                  정답 처리 진행하기
                </DialogButton>
              </Grid.Unit>
            </Grid>
          </Flash>
        ) : null}
        <Grid gapX={5} gapY={3}>
          <Grid.Unit size={[1, 1, 3 / 4]} sx={{ '&>*:not(:first-child)': { marginTop: 8 } }}>
            {showProblem ? (
              <View>
                <Grid sx={{ marginBottom: 3 }}>
                  <Grid.Unit size={'max'}>
                    <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>문제 정보</Text>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <Stack gapX={2} wrap={true}>
                      {actions.includes('task_problem_typing') ? (
                        <Stack.Item>
                          <DialogButton
                            variant={'outline'}
                            size={'large'}
                            renderDialog={({ isOpen, closeDialog }) => (
                              <TaskProblemTypingDialog
                                isOpen={isOpen}
                                onDismiss={closeDialog}
                                task={task}
                                animation={false}
                                config={{
                                  onCompleted: () => {
                                    closeDialog();
                                    toast('저장이 완료됐어요!', 'success');
                                  },
                                }}
                                full
                              />
                            )}
                          >
                            타이핑하기
                          </DialogButton>
                        </Stack.Item>
                      ) : null}
                      {actions.includes('task_problem_update') ? (
                        <Stack.Item>
                          <DialogButton
                            variant={'outline'}
                            size={'large'}
                            renderDialog={({ isOpen, closeDialog }) => (
                              <TaskProblemUpdateDialog
                                isOpen={isOpen}
                                onDismiss={closeDialog}
                                task={task}
                                animation={false}
                                config={{
                                  onCompleted: () => {
                                    closeDialog();
                                    toast('저장이 완료됐어요!', 'success');
                                    refresh();
                                  },
                                }}
                                full
                              />
                            )}
                          >
                            창작하기
                          </DialogButton>
                        </Stack.Item>
                      ) : null}
                      {actions.includes('task_image_creation_update') ? (
                        <Stack.Item>
                          <DialogButton
                            variant={'outline'}
                            size={'large'}
                            renderDialog={({ isOpen, closeDialog }) => (
                              <TaskImageCreationUpdateDialog
                                isOpen={isOpen}
                                onDismiss={closeDialog}
                                task={task}
                                animation={false}
                                config={{
                                  onCompleted: () => {
                                    closeDialog();
                                    toast('저장이 완료됐어요!', 'success');
                                  },
                                }}
                                full
                              />
                            )}
                          >
                            이미지 제작하기
                          </DialogButton>
                        </Stack.Item>
                      ) : null}
                      {actions.includes('task_labeling_problem_update') ? (
                        <Stack.Item>
                          <DialogButton
                            variant={'outline'}
                            size={'large'}
                            renderDialog={({ isOpen, closeDialog }) => (
                              <TaskLabelingProblemUpdateDialog
                                isOpen={isOpen}
                                onDismiss={closeDialog}
                                task={task}
                                animation={false}
                                config={{
                                  onCompleted: () => {
                                    closeDialog();
                                    toast('저장이 완료됐어요!', 'success');
                                  },
                                }}
                                full
                              />
                            )}
                          >
                            수정하기
                          </DialogButton>
                        </Stack.Item>
                      ) : null}
                      {actions.includes('task_first_labeling') ? (
                        <Stack.Item>
                          <DialogButton
                            variant={'outline'}
                            size={'large'}
                            renderDialog={({ isOpen, closeDialog }) => (
                              <TaskFirstLabelingDialog
                                isOpen={isOpen}
                                onDismiss={closeDialog}
                                task={task}
                                animation={false}
                                config={{
                                  onCompleted: () => {
                                    closeDialog();
                                    toast('저장이 완료됐어요!', 'success');
                                  },
                                }}
                                full
                              />
                            )}
                          >
                            1차 라벨링하기
                          </DialogButton>
                        </Stack.Item>
                      ) : null}
                      {actions.includes('task_second_labeling') ? (
                        <Stack.Item>
                          <DialogButton
                            variant={'outline'}
                            size={'large'}
                            renderDialog={({ isOpen, closeDialog }) => (
                              <TaskSecondLabelingDialog
                                isOpen={isOpen}
                                onDismiss={closeDialog}
                                task={task}
                                animation={false}
                                config={{
                                  onCompleted: () => {
                                    closeDialog();
                                    toast('저장이 완료됐어요!', 'success');
                                  },
                                }}
                                full
                              />
                            )}
                          >
                            2차 라벨링하기
                          </DialogButton>
                        </Stack.Item>
                      ) : null}
                      {actions.includes('task_final_labeling') ? (
                        <Stack.Item>
                          <DialogButton
                            variant={'outline'}
                            size={'large'}
                            renderDialog={({ isOpen, closeDialog }) => (
                              <TaskFinalLabelingDialog
                                isOpen={isOpen}
                                onDismiss={closeDialog}
                                task={task}
                                animation={false}
                                config={{
                                  onCompleted: () => {
                                    closeDialog();
                                    toast('저장이 완료됐어요!', 'success');
                                  },
                                }}
                                full
                              />
                            )}
                          >
                            최종 라벨링하기
                          </DialogButton>
                        </Stack.Item>
                      ) : null}
                      {actions.includes('task_hint_feedback_save') ? (
                        <Stack.Item>
                          <DialogButton
                            variant={'outline'}
                            size={'large'}
                            renderDialog={({ isOpen, closeDialog }) => (
                              <TaskHintFeedbackSaveDialog
                                isOpen={isOpen}
                                onDismiss={closeDialog}
                                task={task}
                                animation={false}
                                config={{
                                  onCompleted: () => {
                                    toast('저장이 완료됐어요!', 'success');
                                    closeDialog();
                                  },
                                }}
                                full
                              />
                            )}
                          >
                            힌트 · 피드백 제작하기
                          </DialogButton>
                        </Stack.Item>
                      ) : null}
                    </Stack>
                  </Grid.Unit>
                </Grid>
                <TabHandler initialSelectIndex={0}>
                  {({ selectedIndex, handleSelect }) => (
                    <Grid gapY={3} gapX={2}>
                      <Grid.Unit size={1}>
                        <Stack gapX={1}>
                          <ItemList
                            items={problemTabItems}
                            renderItem={({ label }, index) => (
                              <Button
                                onClick={() => handleSelect(index)}
                                variant={selectedIndex === index ? 'outline' : 'invisible'}
                              >
                                {label}
                              </Button>
                            )}
                            renderItemWrapper={(children, { id }) => <Stack.Item key={id}>{children}</Stack.Item>}
                          />
                        </Stack>
                      </Grid.Unit>
                      {problemTabItems[selectedIndex].id === 'all' ||
                      problemTabItems[selectedIndex].id === 'creation' ? (
                        <>
                          <Grid.Unit size={[1, 1, 1 / 2]}>
                            <View sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                              <Text fontSize={0} color={'fg.subtle'}>
                                창작한 문제
                              </Text>
                              <Card
                                sx={{
                                  marginTop: 1,
                                  maxHeight: problemTabItems[selectedIndex].id === 'all' ? 350 : 750,
                                  overflow: 'auto',
                                  flexGrow: 1,
                                }}
                              >
                                {problem?.problem ? (
                                  <Katex>{problem.problem}</Katex>
                                ) : (
                                  <View
                                    sx={{
                                      display: 'flex',
                                      width: '100%',
                                      height: '100%',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      padding: 3,
                                    }}
                                  >
                                    <EmptyState title={'아직 타이핑한 문제가 없어요'} />
                                  </View>
                                )}
                              </Card>
                            </View>
                          </Grid.Unit>
                          <Grid.Unit size={[1, 1, 1 / 2]}>
                            <View sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                              <Text fontSize={0} color={'fg.subtle'}>
                                창작한 해설
                              </Text>
                              <Card
                                sx={{
                                  marginTop: 1,
                                  maxHeight: problemTabItems[selectedIndex].id === 'all' ? 350 : 750,
                                  flexGrow: 1,
                                  overflow: 'auto',
                                }}
                              >
                                {problem?.solution ? (
                                  <Katex>{problem.solution}</Katex>
                                ) : (
                                  <View
                                    sx={{
                                      display: 'flex',
                                      width: '100%',
                                      height: '100%',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      padding: 3,
                                    }}
                                  >
                                    <EmptyState title={'아직 타이핑한 해설이 없어요'} />
                                  </View>
                                )}
                              </Card>
                            </View>
                          </Grid.Unit>
                        </>
                      ) : null}
                      {problemTabItems[selectedIndex].id === 'all' || problemTabItems[selectedIndex].id === 'scrap' ? (
                        <>
                          <Grid.Unit size={[1, 1, 1 / 2]}>
                            <View sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                              <Text fontSize={0} color={'fg.subtle'}>
                                스크랩한 문제
                              </Text>
                              <Card
                                sx={{
                                  marginTop: 1,
                                  maxHeight: problemTabItems[selectedIndex].id === 'all' ? 350 : 750,
                                  overflowY: 'auto',
                                  flexGrow: 1,
                                }}
                              >
                                <ItemList
                                  items={scrap?.problems || []}
                                  renderItem={({ objectUrl }) => (
                                    <Image
                                      key={objectUrl}
                                      src={objectUrl}
                                      alt={objectUrl}
                                      html
                                      style={{ display: 'block' }}
                                    />
                                  )}
                                  emptyState={
                                    <View
                                      sx={{
                                        display: 'flex',
                                        width: '100%',
                                        height: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: 3,
                                      }}
                                    >
                                      <EmptyState title={'스크랩된 문제가 없습니다'} />
                                    </View>
                                  }
                                />
                              </Card>
                            </View>
                          </Grid.Unit>
                          <Grid.Unit size={[1, 1, 1 / 2]}>
                            <View sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                              <Text fontSize={0} color={'fg.subtle'}>
                                스크랩한 해설
                              </Text>
                              <Card
                                sx={{
                                  marginTop: 1,
                                  maxHeight: problemTabItems[selectedIndex].id === 'all' ? 350 : 750,
                                  overflowY: 'auto',
                                  flexGrow: 1,
                                }}
                              >
                                <ItemList
                                  items={scrap?.solutions || []}
                                  renderItem={({ objectUrl }) => (
                                    <Image
                                      key={objectUrl}
                                      src={objectUrl}
                                      alt={objectUrl}
                                      html
                                      style={{ display: 'block' }}
                                    />
                                  )}
                                  emptyState={
                                    <View
                                      sx={{
                                        display: 'flex',
                                        width: '100%',
                                        height: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: 3,
                                      }}
                                    >
                                      <EmptyState title={'스크랩된 해설이 없습니다'} />
                                    </View>
                                  }
                                />
                              </Card>
                            </View>
                          </Grid.Unit>
                        </>
                      ) : null}
                      <Grid.Unit size={1}>
                        <Card
                          sx={{
                            marginTop: 2,
                            padding: 4,
                          }}
                        >
                          <TaskDescriptionList
                            task={task}
                            type={'default'}
                            titleUnitSize={1 / 4}
                            descriptionUnitSize={3 / 4}
                          />
                        </Card>
                      </Grid.Unit>
                    </Grid>
                  )}
                </TabHandler>
              </View>
            ) : null}
            {showLabeling ? (
              <View>
                <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>라벨링 정보</Text>
                <Card sx={{ padding: 4, marginTop: 3 }}>
                  <TaskDescriptionList
                    task={task}
                    type={'labeling'}
                    titleUnitSize={1 / 4}
                    descriptionUnitSize={3 / 4}
                    renderItemWrapper={(children, _, index) => (
                      <View key={index} sx={{ marginTop: index === 0 ? 0 : 4 }}>
                        {children}
                      </View>
                    )}
                  />
                </Card>
              </View>
            ) : null}
            {showVideo ? (
              <View>
                <Grid>
                  <Grid.Unit size={'max'}>
                    <Stack gapX={1}>
                      <Stack.Item>
                        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>영상</Text>
                      </Stack.Item>
                      <Stack.Item>
                        <Text
                          sx={{
                            fontSize: 3,
                            fontWeight: 'bold',
                            color: videos.totalCount === 0 ? 'neutral.emphasis' : 'accent.emphasis',
                          }}
                        >
                          {videos.totalCount}
                        </Text>
                      </Stack.Item>
                    </Stack>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    {actions.includes('task_video_create') ? (
                      <DialogButton
                        renderDialog={({ isOpen, closeDialog }) => (
                          <TaskVideoCreateDialog
                            wide
                            isOpen={isOpen}
                            onDismiss={closeDialog}
                            taskId={id}
                            config={{
                              onCompleted: () => {
                                closeDialog();
                              },
                            }}
                          />
                        )}
                        leadingIcon={PlusIcon}
                        variant={'primary'}
                      >
                        업로드하기
                      </DialogButton>
                    ) : null}
                  </Grid.Unit>
                </Grid>
                <View sx={{ overflowX: 'auto', marginTop: 3 }}>
                  <TaskVideoConnectionDataTable
                    taskVideoConnection={videos}
                    renderRowWrapper={(children, { id }) => (
                      <RowWrapper
                        key={id}
                        sx={{
                          backgroundColor: solutionVideo?.taskVideo.id === id ? 'success.subtle' : 'inherit',
                        }}
                      >
                        {children}
                      </RowWrapper>
                    )}
                    emptyState={
                      <View sx={{ paddingY: 5 }}>
                        <EmptyState
                          icon={StopIcon}
                          title={'업로드된 영상이 없어요'}
                          description={'문제에 해당하는 해설 영상을 업로드해주세요'}
                        />
                      </View>
                    }
                  />
                </View>
              </View>
            ) : null}
            {showSolutionVideo ? (
              <View>
                <Grid>
                  <Grid.Unit size={'max'}>
                    <Grid sx={{ alignItems: 'center' }} gapX={2}>
                      <Grid.Unit size={[1, 1, 'min']}>
                        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>대표 해설 영상</Text>
                      </Grid.Unit>
                      {solutionVideo ? (
                        <Grid.Unit size={[1, 1, 'max']}>
                          <Label>
                            <DateText>{solutionVideo.modified}</DateText>에 마지막 저장됨
                          </Label>
                        </Grid.Unit>
                      ) : null}
                    </Grid>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    {actions.includes('task_solution_video_update') && solutionVideo ? (
                      <DialogButton
                        renderDialog={({ isOpen, closeDialog }) => (
                          <TaskSolutionVideoUpdateDialog
                            taskId={id}
                            taskSolutionVideo={solutionVideo}
                            isOpen={isOpen}
                            onDismiss={closeDialog}
                            config={{
                              onCompleted: () => {
                                closeDialog();
                              },
                            }}
                            wide
                          />
                        )}
                        leadingIcon={PencilIcon}
                        variant={'primary'}
                      >
                        수정하기
                      </DialogButton>
                    ) : null}
                    {actions.includes('task_solution_video_create') ? (
                      <DialogButton
                        renderDialog={({ isOpen, closeDialog }) => (
                          <TaskSolutionVideoCreateDialog
                            taskId={id}
                            isOpen={isOpen}
                            onDismiss={closeDialog}
                            config={{
                              onCompleted: () => {
                                closeDialog();
                              },
                            }}
                            wide
                          />
                        )}
                        variant={'primary'}
                      >
                        지정하기
                      </DialogButton>
                    ) : null}
                  </Grid.Unit>
                </Grid>
                <View sx={{ marginTop: 3 }}>
                  {solutionVideo ? (
                    <>
                      <Card sx={{ padding: 3 }}>
                        <ItemList
                          items={[
                            {
                              id: 'isPremium',
                              title: '콘텐츠',
                              description: solutionVideo.isPremium ? '유료' : '무료',
                            },
                            {
                              id: 'isRecommended',
                              title: '매력',
                              description: solutionVideo.isRecommended ? '높음' : '무난함',
                            },
                          ]}
                          renderItem={({ title, description }) => (
                            <Grid gapX={1}>
                              <Grid.Unit size={1 / 8}>
                                <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
                              </Grid.Unit>
                              <Grid.Unit size={7 / 8}>
                                <Text sx={{ fontSize: 1 }}>{description}</Text>
                              </Grid.Unit>
                            </Grid>
                          )}
                          renderItemWrapper={(children, { id }, i) => (
                            <View key={id} sx={{ marginTop: i > 0 ? 1 : 0 }}>
                              {children}
                            </View>
                          )}
                        />
                      </Card>
                      <View sx={{ marginTop: 3 }}>
                        <TaskSolutionVideoGrid taskSolutionVideo={solutionVideo} />
                      </View>
                    </>
                  ) : (
                    <Card sx={{ paddingY: 5 }}>
                      <EmptyState
                        icon={StopIcon}
                        title={'지정된 영상이 없어요'}
                        description={'대표 해설 영상을 지정해주세요'}
                      />
                    </Card>
                  )}
                </View>
              </View>
            ) : null}
            <View>
              <Stack gapX={1}>
                <Stack.Item>
                  <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>힌트</Text>
                </Stack.Item>
                {hint ? (
                  <Stack.Item>
                    <TaskHintStatusLabel taskHint={hint} />
                  </Stack.Item>
                ) : null}
              </Stack>
              <Grid gapX={3} sx={{ paddingTop: 3 }}>
                <Grid.Unit size={'min'}>
                  <Text fontSize={1} color={'fg.subtle'}>
                    힌트
                  </Text>
                  <Card
                    sx={{
                      marginTop: 1,
                      width: 'fit-content',
                    }}
                  >
                    {hint?.text ? (
                      <ScrollMiniKatex>{hint?.text}</ScrollMiniKatex>
                    ) : (
                      <FixedMiniView>
                        <Space py={4}>
                          <EmptyState title={'제작한 힌트가 없어요'} />
                        </Space>
                      </FixedMiniView>
                    )}
                  </Card>
                </Grid.Unit>
                {isHintPublished ? (
                  <Grid.Unit size={'min'}>
                    <Text fontSize={1} color={'fg.subtle'}>
                      출시된 힌트
                    </Text>
                    <Card
                      sx={{
                        marginTop: 1,
                        width: 'fit-content',
                      }}
                    >
                      <ScrollMiniKatex>{hint?.publishedText}</ScrollMiniKatex>
                    </Card>
                  </Grid.Unit>
                ) : null}
              </Grid>
            </View>
            <View>
              <Stack gapX={1}>
                <Stack.Item>
                  <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>다시 풀기 피드백</Text>
                </Stack.Item>
                {submitFeedback ? (
                  <Stack.Item>
                    <TaskSubmitFeedbackStatusLabel taskSubmitFeedback={submitFeedback} />
                  </Stack.Item>
                ) : null}
              </Stack>
              <Grid gapX={3} sx={{ paddingTop: 3 }}>
                <Grid.Unit size={[1, 1, 1 / 2]} sx={{ '&>*:not(:first-child)': { marginTop: 3 } }}>
                  <Card sx={{ padding: 4 }}>
                    <DescriptionList
                      item={submitFeedback as any}
                      itemDescriptions={{
                        specialAnswer: {
                          title: '특정 오답',
                          renderValue: (submitFeedback) => submitFeedback?.specialAnswer ?? '-',
                        },
                      }}
                      picks={['specialAnswer']}
                      titleUnitSize={1 / 3}
                      descriptionUnitSize={2 / 3}
                    />
                  </Card>
                  <View>
                    <Text fontSize={1} color={'fg.subtle'}>
                      특정 오답
                    </Text>
                    <Card
                      sx={{
                        marginTop: 1,
                        width: 'fit-content',
                      }}
                    >
                      {submitFeedback?.special ? (
                        <ScrollMiniKatex>{submitFeedback?.special}</ScrollMiniKatex>
                      ) : (
                        <FixedMiniView>
                          <Space py={4}>
                            <EmptyState title={'제작한 피드백이 없어요'} />
                          </Space>
                        </FixedMiniView>
                      )}
                    </Card>
                  </View>
                  <View>
                    <Text fontSize={1} color={'fg.subtle'}>
                      일반 오답
                    </Text>
                    <Card
                      sx={{
                        marginTop: 1,
                        width: 'fit-content',
                      }}
                    >
                      {submitFeedback?.general ? (
                        <ScrollMiniKatex>{submitFeedback?.general}</ScrollMiniKatex>
                      ) : (
                        <FixedMiniView>
                          <Space py={4}>
                            <EmptyState title={'제작한 피드백이 없어요'} />
                          </Space>
                        </FixedMiniView>
                      )}
                    </Card>
                  </View>
                </Grid.Unit>
                {isSubmitFeedbackPublished ? (
                  <Grid.Unit size={[1, 1, 1 / 2]} sx={{ '&>*:not(:first-child)': { marginTop: 3 } }}>
                    <Card sx={{ padding: 4 }}>
                      <DescriptionList
                        item={submitFeedback}
                        itemDescriptions={{
                          publishedSpecialAnswer: {
                            title: '출시된 특정 오답',
                            renderValue: ({ publishedSpecialAnswer }) => publishedSpecialAnswer,
                          },
                        }}
                        picks={['publishedSpecialAnswer']}
                        titleUnitSize={1 / 3}
                        descriptionUnitSize={2 / 3}
                      />
                    </Card>
                    <View>
                      <Text fontSize={1} color={'fg.subtle'}>
                        출시된 특정 오답
                      </Text>
                      <Card sx={{ marginTop: 1, width: 'fit-content' }}>
                        {submitFeedback?.publishedSpecial ? (
                          <ScrollMiniKatex>{submitFeedback?.publishedSpecial}</ScrollMiniKatex>
                        ) : (
                          <FixedMiniView>
                            <Space py={4}>
                              <EmptyState title={'제작한 피드백이 없어요'} />
                            </Space>
                          </FixedMiniView>
                        )}
                      </Card>
                    </View>
                    <View>
                      <Text fontSize={1} color={'fg.subtle'}>
                        출시된 일반 오답
                      </Text>
                      <Card sx={{ marginTop: 1, width: 'fit-content' }}>
                        {submitFeedback?.publishedGeneral ? (
                          <ScrollMiniKatex>{submitFeedback?.publishedGeneral}</ScrollMiniKatex>
                        ) : (
                          <FixedMiniView>
                            <Space py={4}>
                              <EmptyState title={'제작한 피드백이 없어요'} />
                            </Space>
                          </FixedMiniView>
                        )}
                      </Card>
                    </View>
                  </Grid.Unit>
                ) : null}
              </Grid>
            </View>
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1 / 4]}>
            <View sx={{ '&>*:not(:first-child)': { marginTop: 8 } }}>
              <View>
                <TaskDescriptionList
                  task={task}
                  type={'activity'}
                  titleUnitSize={'max'}
                  descriptionUnitSize={'min'}
                  renderItemWrapper={(children, _, i) => (
                    <View key={i} sx={{ marginTop: i > 0 ? 2 : 0 }}>
                      {children}
                    </View>
                  )}
                />
              </View>
              <View>
                <ErrorBoundary>
                  <TaskTypingAutomationHistoryPaginator fragmentReference={task}>
                    {({ typingAutomationHistories }, { isLoadingNext, hasNext, loadMore, refetch }) => {
                      return (
                        <>
                          <Grid sx={{ alignItems: 'center' }}>
                            <Grid.Unit size={'max'}>
                              <Text fontWeight={'bold'} fontSize={1}>
                                프롬프트 히스토리
                              </Text>
                            </Grid.Unit>
                            <Grid.Unit size={'min'}>
                              <DialogButton
                                variant={'outline'}
                                size={'large'}
                                renderDialog={({ isOpen, closeDialog }) => (
                                  <TaskTypingAutomationHistoryCreateDialog
                                    task={task}
                                    isOpen={isOpen}
                                    onDismiss={closeDialog}
                                    config={{
                                      onCompleted: () => {
                                        toast('프롬프트가 생성됐어요', 'success');
                                        refetch({});
                                        closeDialog();
                                      },
                                      onError: () => {
                                        toast('다시 생성해 주세요', 'error');
                                      },
                                    }}
                                  />
                                )}
                              >
                                생성하기
                              </DialogButton>
                            </Grid.Unit>
                          </Grid>
                          <View paddingTop={3}>
                            {typingAutomationHistories.edges.length > 0 ? (
                              <Timeline>
                                <ItemList
                                  items={typingAutomationHistories.edges}
                                  renderItem={({ node }) => (
                                    <TypingAutomationHistoryTimelineItem typingAutomationHistory={node} />
                                  )}
                                  renderItemWrapper={(children, { node }) => (
                                    <React.Fragment key={node.id}>
                                      <DialogHandler
                                        renderDialog={({ isOpen, closeDialog }) => (
                                          <TypingAutomationHistoryDialog
                                            typingAutomationHistory={node}
                                            isOpen={isOpen}
                                            onDismiss={closeDialog}
                                          />
                                        )}
                                      >
                                        <View
                                          sx={{
                                            'borderRadius': 2,
                                            'cursor': 'pointer',
                                            'transition': 'background-color 250ms',
                                            ':hover': { backgroundColor: 'canvas.subtle' },
                                          }}
                                        >
                                          <View sx={{ paddingX: 2 }}>{children}</View>
                                        </View>
                                      </DialogHandler>
                                    </React.Fragment>
                                  )}
                                />
                                {hasNext ? (
                                  <Button sx={{ width: '100%' }} disabled={isLoadingNext} onClick={() => loadMore(10)}>
                                    더보기
                                  </Button>
                                ) : (
                                  <Timeline.Break />
                                )}
                              </Timeline>
                            ) : (
                              <View py={5}>
                                <EmptyState title={'히스토리가 없어요'} />
                              </View>
                            )}
                          </View>
                        </>
                      );
                    }}
                  </TaskTypingAutomationHistoryPaginator>
                </ErrorBoundary>
              </View>
              {problemSolveInfo ? (
                <>
                  <View>
                    <Text fontWeight={'bold'} fontSize={1}>
                      풀이 데이터
                    </Text>
                    <Card sx={{ marginTop: 2, padding: 3 }}>
                      <TaskProblemSolveInfoDescriptionList
                        taskProblemSolveInfo={problemSolveInfo}
                        titleUnitSize={1 / 2}
                        descriptionUnitSize={1 / 2}
                      />
                    </Card>
                  </View>
                  <View>
                    <Text fontWeight={'bold'} fontSize={1}>
                      특정 오답
                    </Text>
                    <Card sx={{ marginTop: 2, padding: 3 }}>
                      <ItemList
                        items={problemSolveInfo.specialAnswers}
                        renderItem={({ rank, answer, percentage }) => (
                          <Grid wrap={false}>
                            <Grid.Unit size={1 / 2}>
                              <Text sx={{ fontSize: 1, color: 'fg.muted' }}>{`${rank}순위`}</Text>
                            </Grid.Unit>
                            <Grid.Unit size={1 / 2}>
                              <Grid wrap={false}>
                                <Grid.Unit size={'max'}>
                                  <Text sx={{ fontSize: 1 }}>{answer || '-'}</Text>
                                </Grid.Unit>
                                <Grid.Unit size={'min'}>
                                  <Text sx={{ fontSize: 1 }}>{`${!isNullable(percentage) ? percentage : 'N'}%`}</Text>
                                </Grid.Unit>
                              </Grid>
                            </Grid.Unit>
                          </Grid>
                        )}
                        renderItemWrapper={(children, { rank }, i) => (
                          <View key={rank} sx={{ marginTop: i > 0 ? 2 : 0 }}>
                            {children}
                          </View>
                        )}
                      />
                    </Card>
                  </View>
                </>
              ) : null}
              <View>
                <ErrorBoundary>
                  <ErrorReportPaginator fragmentReference={errorReports}>
                    {({ errorReports }, { loadMore, hasNext, isLoadingNext, refetch }) => (
                      <>
                        <Stack gapX={1}>
                          <Stack.Item>
                            <Text fontWeight={'bold'} fontSize={1}>
                              오류 신고
                            </Text>
                          </Stack.Item>
                          <Stack.Item>
                            <Text
                              sx={{ fontSize: 1, fontWeight: 'bold' }}
                              color={errorReports.totalCount === 0 ? 'neutral.emphasis' : 'accent.emphasis'}
                            >
                              {numberWithCommas(errorReports.totalCount || 0)}
                            </Text>
                          </Stack.Item>
                        </Stack>
                        <View sx={{ marginTop: 2 }}>
                          <ItemList
                            items={errorReports.edges.map(({ node }) => node).filter((node) => !!node)}
                            renderItem={(node) => <ErrorReportItem errorReport={node} />}
                            renderItemWrapper={(children, { id }, index) => (
                              <Card key={id} sx={{ marginTop: index === 0 ? 0 : 2 }}>
                                <Grid wrap={false}>
                                  <Grid.Unit size={'max'} sx={{ margin: 3 }}>
                                    {children}
                                  </Grid.Unit>
                                  <Grid.Unit size={'min'} sx={{ margin: 1 }}>
                                    <MutationConfirmIconButton<TaskId_errorReportDeleteMutation>
                                      mutation={graphql`
                                        mutation TaskId_errorReportDeleteMutation($input: ErrorReportDeleteInput!) {
                                          errorReportDelete(input: $input) {
                                            id
                                          }
                                        }
                                      `}
                                      input={{ id }}
                                      icon={XIcon}
                                      variant={'plain'}
                                      size={'small'}
                                      aria-label={'delete error report'}
                                      message={'오류 신고를 삭제할까요?'}
                                      config={{
                                        onCompleted: () => {
                                          refetch({}, { fetchPolicy: 'store-and-network' });
                                          toast('오류 신고를 삭제했어요!', 'success');
                                        },
                                        onError: () => {
                                          toast('오류 신고 삭제에 실패했어요', 'error');
                                        },
                                      }}
                                    />
                                  </Grid.Unit>
                                </Grid>
                              </Card>
                            )}
                            emptyState={
                              <Card sx={{ padding: 3 }}>
                                <EmptyState title={'신고된 오류가 없어요'} />
                              </Card>
                            }
                          />
                          {hasNext ? (
                            <Button
                              onClick={() => loadMore(5)}
                              disabled={isLoadingNext}
                              sx={{ width: '100%', marginTop: 1 }}
                            >
                              더보기
                            </Button>
                          ) : null}
                        </View>
                      </>
                    )}
                  </ErrorReportPaginator>
                </ErrorBoundary>
              </View>
              <View>
                <ErrorBoundary>
                  <CommentPaginator fragmentReference={comments}>
                    {({ comments }, { loadMore, hasNext, isLoadingNext, refetch }) => {
                      return (
                        <>
                          <View>
                            <Stack gapX={1}>
                              <Stack.Item>
                                <Text fontWeight={'bold'} fontSize={1}>
                                  댓글
                                </Text>
                              </Stack.Item>
                              <Stack.Item>
                                <Text
                                  sx={{ fontSize: 1, fontWeight: 'bold' }}
                                  color={comments.totalCount === 0 ? 'neutral.emphasis' : 'accent.emphasis'}
                                >
                                  {numberWithCommas(comments.totalCount || 0)}
                                </Text>
                              </Stack.Item>
                            </Stack>
                            <Card ref={commentContainerRef} sx={{ maxHeight: 240, overflowY: 'auto', marginTop: 2 }}>
                              <View sx={{ padding: 3 }}>
                                <ItemList
                                  items={comments.edges.map(({ node }) => node).filter((node) => !!node)}
                                  renderItem={(node) => <CommentMutationActionItem comment={node} />}
                                  renderItemWrapper={(children, { id }, index) => (
                                    <View key={id} sx={{ marginTop: index === 0 ? 0 : 4 }}>
                                      {children}
                                    </View>
                                  )}
                                  emptyState={<EmptyState title={'댓글이 없어요'} />}
                                />
                                {hasNext ? (
                                  <Button
                                    onClick={() => loadMore(3)}
                                    disabled={isLoadingNext}
                                    sx={{ width: '100%', marginTop: 1 }}
                                  >
                                    더보기
                                  </Button>
                                ) : null}
                              </View>
                            </Card>
                          </View>
                          <View sx={{ marginTop: 2 }}>
                            <CommentCreateMutationFormik
                              nodeId={taskId as string}
                              config={{
                                onCompleted: () => {
                                  refetch(
                                    {},
                                    {
                                      fetchPolicy: 'store-and-network',
                                      onComplete: () => {
                                        setTimeout(() => {
                                          if (commentContainerRef.current)
                                            scrollToBottom(commentContainerRef.current, {});
                                        }, 0);
                                      },
                                    },
                                  );
                                },
                              }}
                            />
                          </View>
                        </>
                      );
                    }}
                  </CommentPaginator>
                </ErrorBoundary>
              </View>
              <View>
                <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>히스토리</Text>
                <ErrorBoundary>
                  <TaskTaskHistoryPaginator fragmentReference={task}>
                    {({ histories }, { isLoadingNext, hasNext, loadMore }) => (
                      <Timeline>
                        <ItemList
                          items={histories.edges}
                          renderItem={({ node }) => <TaskHistoryTimelineItem key={node.id} taskHistory={node} />}
                          renderItemWrapper={(children, { node }) => {
                            const { id, type } = node;
                            return (
                              <React.Fragment key={id}>
                                {type === 'task_video_create' ||
                                type === 'task_problem_typing' ||
                                type === 'task_problem_creation' ||
                                type === 'task_image_creation' ||
                                type === 'task_first_labeling' ||
                                type === 'task_second_labeling' ||
                                type === 'task_publish' ||
                                type === 'task_solution_video_create' ||
                                type === 'task_solution_video_update' ||
                                type === 'task_hint_feedback_publish' ? (
                                  <DialogHandler
                                    renderDialog={({ isOpen, closeDialog }) => (
                                      <ErrorBoundary>
                                        <TaskHistoryDialog
                                          taskHistory={node}
                                          isOpen={isOpen}
                                          onDismiss={() => closeDialog()}
                                          wide
                                        />
                                      </ErrorBoundary>
                                    )}
                                  >
                                    <View
                                      sx={{
                                        'borderRadius': 2,
                                        'cursor': 'pointer',
                                        'transition': 'background-color 250ms',
                                        ':hover': { backgroundColor: 'canvas.subtle' },
                                      }}
                                    >
                                      <View sx={{ paddingX: 2 }}>{children}</View>
                                    </View>
                                  </DialogHandler>
                                ) : (
                                  <View sx={{ paddingX: 2 }}>{children}</View>
                                )}
                              </React.Fragment>
                            );
                          }}
                        />
                        {hasNext ? (
                          <Button sx={{ width: '100%' }} disabled={isLoadingNext} onClick={() => loadMore(10)}>
                            더보기
                          </Button>
                        ) : (
                          <Timeline.Break />
                        )}
                      </Timeline>
                    )}
                  </TaskTaskHistoryPaginator>
                </ErrorBoundary>
              </View>
            </View>
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

TaskId.getLayout = (page) => (
  <HeaderSidebarNavPageLayout>
    <Suspense fallback={<Spinner />}>{page}</Suspense>
  </HeaderSidebarNavPageLayout>
);
TaskId.authenticated = true;
TaskId.routes = [{ id: 'taskId', pathname: '/task/[taskId]', name: '문제 상세', permissions: ['task_read'] }];

export default TaskId;
