import { graphql } from 'react-relay';

import { NoticeWindowPaginator_query$key } from '../../../relay/__generated__/NoticeWindowPaginator_query.graphql';
import FragmentWindowPaginator, { FragmentWindowPaginatorProps } from '../../core/FragmentWindowPaginator';
import Pagination from '../../page/Pagination';

const NoticeWindowPaginator_query = graphql`
  fragment NoticeWindowPaginator_query on Query
  @argumentDefinitions(
    filters: { type: NoticeFilter }
    order: { type: NoticeOrder }
    page: { type: Int, defaultValue: 1 }
    pageSize: { type: Int, defaultValue: 15 }
  )
  @refetchable(queryName: "NoticeWindowPaginatorRefreshQuery") {
    notices(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {
      totalCount
      pages {
        ...Pagination_pages
      }
      edges {
        node {
          id
          actions
          ...NoticeItem_notice
          ...NoticeUpdateDialog_notice
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentWindowPaginatorProps<NoticeWindowPaginator_query$key>, 'fragment'>;

const NoticeWindowPaginator = ({ ...props }: Props) => (
  <FragmentWindowPaginator fragment={NoticeWindowPaginator_query} {...props}>
    {(data, { loadPage }) =>
      props.children(data, {
        loadPage,
        renderPagination: (props) => <Pagination {...props} pages={data.notices.pages} loadPage={loadPage} />,
      })
    }
  </FragmentWindowPaginator>
);

export default NoticeWindowPaginator;
