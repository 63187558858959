import { graphql } from 'react-relay';

import { BookWindowPaginator_query$key } from '../../../relay/__generated__/BookWindowPaginator_query.graphql';
import FragmentWindowPaginator, { FragmentWindowPaginatorProps } from '../../core/FragmentWindowPaginator';
import Pagination from '../../page/Pagination';

const BookWindowPaginator_query = graphql`
  fragment BookWindowPaginator_query on Query
  @argumentDefinitions(
    filters: { type: BookFilter }
    order: { type: BookOrder }
    page: { type: Int, defaultValue: 1 }
    pageSize: { type: Int, defaultValue: 20 }
  )
  @refetchable(queryName: "BookWindowPaginatorRefreshQuery") {
    books(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {
      totalCount
      pages {
        ...Pagination_pages
      }
      edges {
        node {
          id
        }
      }
      ...BookConnectionDataTable_bookConnection
    }
  }
`;

type Props = {} & Omit<FragmentWindowPaginatorProps<BookWindowPaginator_query$key>, 'fragment'>;

const BookWindowPaginator = ({ ...props }: Props) => (
  <FragmentWindowPaginator fragment={BookWindowPaginator_query} {...props}>
    {(data, { loadPage }) =>
      props.children(data, {
        loadPage,
        renderPagination: (props) => <Pagination {...props} pages={data.books.pages} loadPage={loadPage} />,
      })
    }
  </FragmentWindowPaginator>
);

export default BookWindowPaginator;
