import { LinkIcon, MailIcon, SearchIcon, SyncIcon, TrashIcon } from '@primer/octicons-react';
import { clipboard } from '@teamturing/utils';
import { GraphQLError } from 'graphql/index';
import { useRouter } from 'next/router';
import React, { ComponentProps, Suspense } from 'react';
import { graphql } from 'react-relay';

import BillConnectionDataTable from '../../components/bill/BillConnectionDataTable';
import BillCreateDialog from '../../components/bill/BillCreateDialog';
import BillSendMessageDialog from '../../components/bill/BillSendMessageDialog';
import BillWindowPaginator from '../../components/bill/BillWindowPaginator';
import BillXlsxImportDialog from '../../components/bill/BillXlsxImportDialog';
import Button from '../../components/core/Button';
import DialogButton from '../../components/core/DialogButton';
import DialogIconButton from '../../components/core/DialogIconButton';
import EmptyState from '../../components/core/EmptyState';
import EnumPairSearchOverlayTokenField from '../../components/core/EnumPairSearchOverlayTokenField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import IconButton from '../../components/core/IconButton';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import MutationConfirmIconButton from '../../components/core/MutationConfirmIconButton';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import Tooltip from '../../components/core/Tooltip';
import View from '../../components/core/View';
import UserPaginatorSearchOverlayTokenField from '../../components/user/UserPaginatorSearchOverlayTokenField';
import { usePaginationContext } from '../../contexts/PaginationContext';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useToast from '../../hooks/useToast';
import { bill_billDeleteMutation } from '../../relay/__generated__/bill_billDeleteMutation.graphql';
import { bill_billsQuery, BillStatusEnum, StoreStuffTypeEnum } from '../../relay/__generated__/bill_billsQuery.graphql';
import { parseGraphQLError } from '../../utils/error';
import { numberWithCommas } from '../../utils/number';
import { NextPage } from '../_app';

const billsForBill = graphql`
  query bill_billsQuery($filters: BillFilter, $order: BillOrder, $page: Int, $pageSize: Int) {
    ...BillWindowPaginator_query @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

type Props = {};

const Bill: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();
  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    order: { type: 'string' },
    page: { type: 'number' },

    type_In: { type: 'string', multiple: true },
    createdById_In: { type: 'string', multiple: true },
    status_In: { type: 'string', multiple: true },
  });
  const { pageSize } = usePaginationContext();

  const { search, order, page, type_In, createdById_In, status_In } = initialValues;

  return (
    <View>
      <Head siteTitle={'개인 결제 링크'} />
      <ErrorBoundary>
        <QueryFormik<bill_billsQuery>
          query={billsForBill}
          staticVariables={{ pageSize }}
          initialValues={{
            filters: {
              search,
              type_In: type_In as StoreStuffTypeEnum[],
              createdById_In,
              status_In: status_In as BillStatusEnum[],
            },
            order: order || '-created',
            page,
          }}
          options={{ fetchPolicy: 'store-and-network' }}
          onSubmit={(values) => setParsedUrlQuery({ ...values.filters, order }, { scroll: false })}
        >
          {({ values: { filters, order }, resetForm, setFieldValue, submitForm }, queryReference) => {
            const handleReset = () => {
              resetForm({ values: { order } });
              setParsedUrlQuery({ order }, { scroll: false });
            };

            const handleChangeOrderActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
              newValue,
            ) => {
              setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
            };

            return (
              <View>
                <Grid sx={{ alignItems: 'center' }}>
                  <Grid.Unit size={'max'}>
                    <Text as={'h1'}>개인 결제 링크</Text>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <Stack gapX={2}>
                      <Stack.Item>
                        <DialogButton
                          size={'large'}
                          variant={'default'}
                          renderDialog={({ isOpen, closeDialog }) => (
                            <BillXlsxImportDialog
                              isOpen={isOpen}
                              onDismiss={closeDialog}
                              wide
                              config={{
                                onCompleted: (data) => {
                                  toast(`${data.billXlsxImport.length}개의 개인결제링크가 생성됐어요`, 'success');
                                  closeDialog();
                                },
                              }}
                            />
                          )}
                        >
                          벌크 생성
                        </DialogButton>
                      </Stack.Item>
                      <Stack.Item>
                        <DialogButton
                          size={'large'}
                          variant={'primary'}
                          renderDialog={({ isOpen, closeDialog }) => (
                            <BillCreateDialog
                              isOpen={isOpen}
                              onDismiss={closeDialog}
                              wide
                              config={{
                                onCompleted: () => {
                                  toast('개인 결제 링크가 생성됐어요', 'success');
                                  submitForm();
                                  closeDialog();
                                },
                              }}
                            />
                          )}
                        >
                          생성하기
                        </DialogButton>
                      </Stack.Item>
                    </Stack>
                  </Grid.Unit>
                </Grid>
                <Grid>
                  <Grid.Unit size={'min'}>
                    <QueryFormik.FilterSearchTextField
                      typename={'BillFilter'}
                      label={'Search'}
                      labelConfig={{ visuallyHidden: true }}
                      name={'filters.search'}
                      autoComplete={'off'}
                      leadingVisual={SearchIcon}
                      size={'large'}
                      debounce
                      onChange={(e) => {
                        setFieldValue('filters.search', e.target.value);
                        setTimeout(() => submitForm(), 0);
                      }}
                    />
                  </Grid.Unit>
                  <Grid.Unit size={'max'}>
                    <View sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Stack gapX={2}>
                        <Stack.Item>
                          <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleReset}>
                            초기화
                          </Button>
                        </Stack.Item>
                        <Stack.Item>
                          <QueryFormik.OrderActionMenuButton
                            typename={'BillOrder'}
                            orders={['created']}
                            onChange={handleChangeOrderActionMenu}
                          />
                        </Stack.Item>
                      </Stack>
                    </View>
                  </Grid.Unit>
                </Grid>
                <View sx={{ marginTop: 5 }}>
                  <QueryFormik.FilterGrid
                    onChange={(filters) => {
                      setParsedUrlQuery({ ...filters, order }, { scroll: false });
                    }}
                  >
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'타입'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'StoreStuffTypeEnum'}
                        label={'타입'}
                        name={'filters.type_In'}
                        placeholder={'타입 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'제작자'}>
                      <UserPaginatorSearchOverlayTokenField
                        label={'제작자'}
                        placeholder={'제작자 선택'}
                        name={'filters.createdById_In'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={1} title={'결제 여부'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'BillStatusEnum'}
                        label={'결제 여부'}
                        name={'filters.status_In'}
                        placeholder={'결제 여부 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                  </QueryFormik.FilterGrid>
                </View>
                <View sx={{ marginTop: 5 }}>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<bill_billsQuery>>
                        {(queryReference) => (
                          <BillWindowPaginator
                            fragmentReference={queryReference}
                            onLoadPage={(page) => setParsedUrlQuery({ ...filters, order, page })}
                          >
                            {({ bills }, { renderPagination }) => (
                              <>
                                <View>
                                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                    총 {numberWithCommas(bills?.totalCount || 0)}건
                                  </Text>
                                </View>
                                <View sx={{ marginTop: 3 }}>
                                  <BillConnectionDataTable
                                    billConnection={bills}
                                    onRowClick={({ id }) => router.push({ pathname: `/bill/${id}` })}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState
                                          title={'필터에 맞는 결과가 없어요'}
                                          description={'다른 필터로 다시 시도해보세요.'}
                                        />
                                      </View>
                                    }
                                    renderHead={(columns) => {
                                      const headCommonStyle = {
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'border.default',
                                        textAlign: 'start',
                                        padding: 2,
                                        fontWeight: 'bold',
                                        color: 'fg.muted',
                                      };
                                      return (
                                        <View
                                          as={'thead'}
                                          sx={{
                                            borderBottomWidth: 1,
                                            borderBottomStyle: 'solid',
                                            borderBottomColor: 'border.default',
                                            backgroundColor: 'canvas.subtle',
                                          }}
                                        >
                                          <View as={'tr'}>
                                            <View as={'th'} sx={{ ...headCommonStyle, minWidth: 90 }}>
                                              공유
                                            </View>
                                            {columns.map(({ field, title, width }) => (
                                              <View
                                                key={field}
                                                as={'th'}
                                                sx={{
                                                  minWidth: width,
                                                  ...headCommonStyle,
                                                }}
                                              >
                                                {title}
                                              </View>
                                            ))}
                                            <View as={'th'} sx={{ ...headCommonStyle, minWidth: 48 }}>
                                              삭제
                                            </View>
                                          </View>
                                        </View>
                                      );
                                    }}
                                    renderRow={(row, columns, index) => {
                                      const cellCommonStyle = {
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'border.default',
                                      };

                                      return (
                                        <>
                                          <View
                                            as={'td'}
                                            sx={{ ...cellCommonStyle, paddingX: '12px' }}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <Stack gapX={2}>
                                              <Stack.Item>
                                                <Tooltip direction={'n'} text={'링크'}>
                                                  <IconButton
                                                    icon={LinkIcon}
                                                    aria-label={'copy link'}
                                                    size={'small'}
                                                    disabled={!row.actions.includes('bill_payment_link_copy')}
                                                    onClick={() =>
                                                      clipboard
                                                        .writeText(row.paymentLink)
                                                        .then(() => toast('링크가 복사됐어요', 'success'))
                                                    }
                                                  />
                                                </Tooltip>
                                              </Stack.Item>
                                              <Stack.Item>
                                                <Tooltip direction={'n'} text={'문자'}>
                                                  <DialogIconButton
                                                    icon={MailIcon}
                                                    aria-label={'send sms with link'}
                                                    size={'small'}
                                                    disabled={!row.actions.includes('bill_send_sms')}
                                                    renderDialog={({ isOpen, closeDialog }) => (
                                                      <BillSendMessageDialog
                                                        bill={row}
                                                        isOpen={isOpen}
                                                        onDismiss={closeDialog}
                                                        config={{
                                                          onCompleted: () => {
                                                            toast('개인 결제 링크가 전송됐어요', 'success');
                                                            closeDialog();
                                                          },
                                                        }}
                                                      />
                                                    )}
                                                  />
                                                </Tooltip>
                                              </Stack.Item>
                                            </Stack>
                                          </View>
                                          {columns.map(({ field, renderValue, width, align = 'start' }) => (
                                            <View
                                              key={field}
                                              as={'td'}
                                              sx={{
                                                ...cellCommonStyle,
                                                minWidth: width,
                                                textAlign: align,
                                                padding: 2,
                                              }}
                                            >
                                              {renderValue(row, index)}
                                            </View>
                                          ))}
                                          <View
                                            as={'td'}
                                            sx={{ ...cellCommonStyle, paddingX: '12px' }}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <MutationConfirmIconButton<bill_billDeleteMutation>
                                              mutation={graphql`
                                                mutation bill_billDeleteMutation($input: BillDeleteInput!) {
                                                  billDelete(input: $input) {
                                                    id
                                                  }
                                                }
                                              `}
                                              input={{ id: row.id }}
                                              icon={TrashIcon}
                                              aria-label={'delete bill'}
                                              size={'small'}
                                              disabled={!row.actions.includes('bill_delete')}
                                              message={'제작한 링크를 삭제할까요?'}
                                              config={{
                                                onCompleted: () => {
                                                  toast('삭제가 완료됐어요', 'success');
                                                  submitForm();
                                                },
                                                onError: (error) => {
                                                  toast(
                                                    parseGraphQLError(error as GraphQLError)?.[0].message ||
                                                      '다시 시도해주세요',
                                                    'error',
                                                  );
                                                  submitForm();
                                                },
                                              }}
                                            />
                                          </View>
                                        </>
                                      );
                                    }}
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.()}
                                </View>
                              </>
                            )}
                          </BillWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            );
          }}
        </QueryFormik>
      </ErrorBoundary>
    </View>
  );
};

Bill.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
Bill.authenticated = true;
Bill.routes = [
  {
    id: 'bill',
    icon: LinkIcon,
    pathname: '/bill',
    name: '개인 결제 링크',
    permissions: ['bill_read'],
  },
];

export default Bill;
