import { SearchIcon, SyncIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { Suspense } from 'react';
import { graphql } from 'react-relay';

import CodepushVersionConnectionDataTable from '../../components/codepushVersion/CodepushVersionConnectionDataTable';
import CodepushVersionWindowPaginator from '../../components/codepushVersion/CodepushVersionWindowPaginator';
import Button from '../../components/core/Button';
import EmptyState from '../../components/core/EmptyState';
import EnumPairSearchOverlayTokenField from '../../components/core/EnumPairSearchOverlayTokenField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import {
  codepushVersion_codepushVersionsQuery,
  CodepushVersionStatusEnum,
} from '../../relay/__generated__/codepushVersion_codepushVersionsQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { NextPage } from '../_app';

import codepushVersionId from './[codepushVersionId]';

const codepushVersionsForCodepushVersion = graphql`
  query codepushVersion_codepushVersionsQuery($filters: CodepushVersionFilter, $page: Int, $pageSize: Int) {
    ...CodepushVersionWindowPaginator_query @arguments(filters: $filters, page: $page, pageSize: $pageSize)
  }
`;

type Props = {};

const CodepushVersion: NextPage<Props> = () => {
  const router = useRouter();

  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    status_In: { type: 'string', multiple: true },
    page: { type: 'number' },
  });
  const { search, status_In, page } = initialValues;

  return (
    <View>
      <Head siteTitle={'코드푸시'} />
      <ErrorBoundary>
        <QueryFormik<codepushVersion_codepushVersionsQuery>
          query={codepushVersionsForCodepushVersion}
          staticVariables={{ pageSize: 10 }}
          initialValues={{
            filters: {
              search,
              status_In: status_In as CodepushVersionStatusEnum[],
            },
            page,
          }}
          options={{ fetchPolicy: 'store-and-network' }}
          onSubmit={(values) => setParsedUrlQuery({ ...values.filters, page }, { scroll: false })}
          enableReinitialize
        >
          {({ values: { filters }, submitForm, resetForm, setFieldValue }, queryReference) => {
            const handleReset = () => {
              resetForm({ values: {} });
              setParsedUrlQuery({}, { scroll: false });
            };

            return (
              <View>
                <Text as={'h1'}>코드푸시</Text>
                <Grid>
                  <Grid.Unit size={'min'}>
                    <QueryFormik.FilterSearchTextField
                      typename={'CodepushVersionFilter'}
                      label={'Search'}
                      labelConfig={{ visuallyHidden: true }}
                      name={'filters.search'}
                      size={'large'}
                      autoComplete={'off'}
                      leadingVisual={SearchIcon}
                      debounce
                      onChange={(e) => {
                        setFieldValue('filters.search', e.target.value);
                        setTimeout(() => submitForm(), 0);
                      }}
                    />
                  </Grid.Unit>
                  <Grid.Unit size={'max'}>
                    <View sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleReset}>
                        초기화
                      </Button>
                    </View>
                  </Grid.Unit>
                </Grid>
                <View sx={{ marginTop: 5 }}>
                  <QueryFormik.FilterGrid
                    onChange={(filters) => {
                      setParsedUrlQuery({ ...filters }, { scroll: false });
                    }}
                  >
                    <QueryFormik.FilterGridUnit size={1} title={'상태'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'CodepushVersionStatusEnum'}
                        label={'상태'}
                        name={'filters.status_In'}
                        placeholder={'상태 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                  </QueryFormik.FilterGrid>
                </View>
                <View sx={{ marginTop: 5 }}>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<codepushVersion_codepushVersionsQuery>>
                        {(queryReference) => (
                          <CodepushVersionWindowPaginator
                            fragmentReference={queryReference}
                            onLoadPage={(page) => setParsedUrlQuery({ ...filters, page })}
                          >
                            {({ codepushVersions }, { renderPagination }) => (
                              <>
                                <View>
                                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                    총 {numberWithCommas(codepushVersions.totalCount || 0)}개
                                  </Text>
                                </View>
                                <View sx={{ marginTop: 3 }}>
                                  <CodepushVersionConnectionDataTable
                                    codepushVersionConnection={codepushVersions}
                                    onRowClick={({ id }) => router.push({ pathname: `/codepushVersion/${id}` })}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState
                                          title={'필터에 맞는 결과가 없어요'}
                                          description={'다른 필터로 다시 시도해보세요.'}
                                        />
                                      </View>
                                    }
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.({ enablePageSizeControl: false })}
                                </View>
                              </>
                            )}
                          </CodepushVersionWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            );
          }}
        </QueryFormik>
      </ErrorBoundary>
    </View>
  );
};

CodepushVersion.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
CodepushVersion.authenticated = true;
CodepushVersion.routes = [
  {
    id: 'codepushVersion',
    name: '코드푸시',
    pathname: '/codepushVersion',
    icon: SyncIcon,
    permissions: ['codepush_version_read'],
  },
  ...codepushVersionId.routes,
];

export default CodepushVersion;
