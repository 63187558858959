import { graphql } from 'react-relay';

import { AppPushWindowPaginator_query$key } from '../../../relay/__generated__/AppPushWindowPaginator_query.graphql';
import FragmentWindowPaginator, { FragmentWindowPaginatorProps } from '../../core/FragmentWindowPaginator';
import Pagination from '../../page/Pagination';

const AppPushWindowPaginator_query = graphql`
  fragment AppPushWindowPaginator_query on Query
  @argumentDefinitions(
    filters: { type: AppPushFilter }
    order: { type: AppPushOrder }
    page: { type: Int, defaultValue: 1 }
    pageSize: { type: Int }
  )
  @refetchable(queryName: "AppPushWindowPaginatorRefreshQuery") {
    appPushs(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {
      totalCount
      pages {
        ...Pagination_pages
      }
      edges {
        node {
          id
        }
      }
      ...AppPushConnectionDataTable_appPushConnection
    }
  }
`;

type Props = {} & Omit<FragmentWindowPaginatorProps<AppPushWindowPaginator_query$key>, 'fragment'>;

const AppPushWindowPaginator = ({ ...props }: Props) => (
  <FragmentWindowPaginator fragment={AppPushWindowPaginator_query} {...props}>
    {(data, { loadPage }) =>
      props.children(data, {
        loadPage,
        renderPagination: (props) => <Pagination {...props} pages={data.appPushs.pages} loadPage={loadPage} />,
      })
    }
  </FragmentWindowPaginator>
);

export default AppPushWindowPaginator;
