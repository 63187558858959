import { graphql } from 'react-relay';

import { ScrapSourceWindowPaginator_query$key } from '../../../relay/__generated__/ScrapSourceWindowPaginator_query.graphql';
import FragmentWindowPaginator, { FragmentWindowPaginatorProps } from '../../core/FragmentWindowPaginator';
import Pagination from '../../page/Pagination';

const ScrapSourceWindowPaginator_query = graphql`
  fragment ScrapSourceWindowPaginator_query on Query
  @argumentDefinitions(
    filters: { type: ScrapSourceFilter }
    order: { type: ScrapSourceOrder }
    page: { type: Int, defaultValue: 1 }
    pageSize: { type: Int, defaultValue: 20 }
  )
  @refetchable(queryName: "ScrapSourceWindowPaginatorRefreshQuery") {
    scrapSources(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {
      totalCount
      pages {
        ...Pagination_pages
      }
      edges {
        node {
          id
        }
      }
      ...ScrapSourceConnectionDataTable_scrapSourceConnection
    }
  }
`;

type Props = {} & Omit<FragmentWindowPaginatorProps<ScrapSourceWindowPaginator_query$key>, 'fragment'>;

const ScrapSourceWindowPaginator = ({ ...props }: Props) => (
  <FragmentWindowPaginator fragment={ScrapSourceWindowPaginator_query} {...props}>
    {(data, { loadPage }) =>
      props.children(data, {
        loadPage,
        renderPagination: (props) => <Pagination {...props} pages={data.scrapSources.pages} loadPage={loadPage} />,
      })
    }
  </FragmentWindowPaginator>
);

export default ScrapSourceWindowPaginator;
