import { FileIcon, SearchIcon, SyncIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import React, { ComponentProps, Suspense } from 'react';
import { graphql } from 'react-relay';

import Button from '../../components/core/Button';
import DialogButton from '../../components/core/DialogButton';
import EmptyState from '../../components/core/EmptyState';
import EnumPairCheckboxGroupField from '../../components/core/EnumPairCheckboxGroupField';
import EnumPairSearchOverlayTokenField from '../../components/core/EnumPairSearchOverlayTokenField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import QuizConnectionDataTable from '../../components/quiz/QuizConnectionDataTable';
import QuizCreateDialog from '../../components/quiz/QuizCreateDialog';
import QuizImageExportDialog from '../../components/quiz/QuizImageExportDialog';
import QuizWindowPaginator from '../../components/quiz/QuizWindowPaginator';
import UnitAPaginatorSearchOverlayTokenField from '../../components/unitA/UnitAPaginatorSearchOverlayTokenField';
import UnitBPaginatorSearchOverlayTokenField from '../../components/unitB/UnitBPaginatorSearchOverlayTokenField';
import UnitCPaginatorSearchOverlayTokenField from '../../components/unitC/UnitCPaginatorSearchOverlayTokenField';
import UnitDPaginatorSearchOverlayTokenField from '../../components/unitD/UnitDPaginatorSearchOverlayTokenField';
import UserPaginatorSearchOverlayTokenField from '../../components/user/UserPaginatorSearchOverlayTokenField';
import { usePaginationContext } from '../../contexts/PaginationContext';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useToast from '../../hooks/useToast';
import { quiz_quizzesQuery, QuizStatusEnum } from '../../relay/__generated__/quiz_quizzesQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { NextPage } from '../_app';

import QuizId from './[quizId]';

const quizzesForQuiz = graphql`
  query quiz_quizzesQuery($filters: QuizFilter, $order: QuizOrder, $page: Int, $pageSize: Int) {
    ...QuizWindowPaginator_query @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

type Props = {};

const Quiz: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();

  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    status_In: { type: 'string', multiple: true },
    createdById_In: { type: 'string', multiple: true },
    answer_In: { type: 'string', multiple: true },
    unitAId_In: { type: 'string', multiple: true },
    unitBId_In: { type: 'string', multiple: true },
    unitCId_In: { type: 'string', multiple: true },
    unitDId_In: { type: 'string', multiple: true },
    order: { type: 'string' },
    page: { type: 'number' },
  });
  const { pageSize } = usePaginationContext();

  const { search, status_In, createdById_In, answer_In, unitAId_In, unitBId_In, unitCId_In, unitDId_In, page, order } =
    initialValues;

  return (
    <View>
      <Head title={'TCMS'} siteTitle={'OX 퀴즈'} />
      <ErrorBoundary>
        <QueryFormik<quiz_quizzesQuery>
          query={quizzesForQuiz}
          staticVariables={{ pageSize }}
          initialValues={{
            filters: {
              search,
              createdById_In,
              answer_In,
              unitAId_In,
              unitBId_In,
              unitCId_In,
              unitDId_In,
              status_In: status_In as QuizStatusEnum[],
            },
            order: order || '-created',
            page,
          }}
          options={{ fetchPolicy: 'store-and-network' }}
          onSubmit={(values) => setParsedUrlQuery({ ...values.filters, order }, { scroll: false })}
          enableReinitialize
        >
          {({ values: { filters, order }, setFieldValue, submitForm, resetForm }, queryReference) => {
            const handleReset = () => {
              resetForm({ values: { order } });
              setParsedUrlQuery({ order }, { scroll: false });
            };

            const handleChangeOrderActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
              newValue,
            ) => {
              setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
            };

            return (
              <View>
                <Grid sx={{ alignItems: 'center' }}>
                  <Grid.Unit size={[1, 'max']}>
                    <Text as={'h1'}>OX 퀴즈</Text>
                  </Grid.Unit>
                  <Grid.Unit size={[1, 'min']}>
                    <Stack gapX={2}>
                      <Stack.Item>
                        <DialogButton
                          size={'large'}
                          renderDialog={({ isOpen, closeDialog }) => (
                            <QuizImageExportDialog isOpen={isOpen} onDismiss={closeDialog} wide />
                          )}
                        >
                          퀴즈 이미지 추출하기
                        </DialogButton>
                      </Stack.Item>
                      <Stack.Item>
                        <DialogButton
                          variant={'primary'}
                          size={'large'}
                          renderDialog={({ isOpen, closeDialog }) => (
                            <QuizCreateDialog
                              isOpen={isOpen}
                              onDismiss={closeDialog}
                              wide
                              config={{
                                onCompleted: () => {
                                  toast('OX 퀴즈 생성이 완료됐어요', 'success');
                                  submitForm();
                                },
                                onError: () => {
                                  toast('다시 생성해 주세요', 'error');
                                },
                              }}
                            />
                          )}
                        >
                          생성하기
                        </DialogButton>
                      </Stack.Item>
                    </Stack>
                  </Grid.Unit>
                </Grid>
                <Grid>
                  <Grid.Unit size={'min'}>
                    <QueryFormik.FilterSearchTextField
                      typename={'QuizFilter'}
                      label={'Search'}
                      labelConfig={{ visuallyHidden: true }}
                      name={'filters.search'}
                      size={'large'}
                      autoComplete={'off'}
                      leadingVisual={SearchIcon}
                      debounce
                      onChange={(e) => {
                        setFieldValue('filters.search', e.target.value);
                        setTimeout(() => submitForm(), 0);
                      }}
                    />
                  </Grid.Unit>
                  <Grid.Unit size={'max'}>
                    <View sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Stack gapX={2}>
                        <Stack.Item>
                          <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleReset}>
                            초기화
                          </Button>
                        </Stack.Item>
                        <Stack.Item>
                          <QueryFormik.OrderActionMenuButton
                            typename={'QuizOrder'}
                            orders={['modified', 'created']}
                            onChange={handleChangeOrderActionMenu}
                          />
                        </Stack.Item>
                      </Stack>
                    </View>
                  </Grid.Unit>
                </Grid>
                <View sx={{ marginTop: 5 }}>
                  <QueryFormik.FilterGrid
                    onChange={(filters) => {
                      setParsedUrlQuery({ ...filters, order }, { scroll: false });
                    }}
                  >
                    <QueryFormik.FilterGridUnit size={1} title={'상태'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'QuizStatusEnum'}
                        label={'상태'}
                        name={'filters.status_In'}
                        placeholder={'상태 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={1} title={'Unit'}>
                      <UnitAPaginatorSearchOverlayTokenField
                        label={'Unit A'}
                        name={'filters.unitAId_In'}
                        placeholder={'Unit A 선택'}
                        variables={{
                          order: { order: 'ASC' },
                          filters: { unitSchoolType_In: ['high_school', 'middle_school'] },
                        }}
                      />
                      <UnitBPaginatorSearchOverlayTokenField
                        label={'Unit B'}
                        name={'filters.unitBId_In'}
                        placeholder={'Unit B 선택'}
                        variables={{
                          order: { order: 'ASC' },
                          filters: {
                            unitSchoolType_In: ['high_school', 'middle_school'],
                            unitAId_In: filters?.unitAId_In,
                          },
                        }}
                      />
                      <UnitCPaginatorSearchOverlayTokenField
                        label={'Unit C'}
                        name={'filters.unitCId_In'}
                        placeholder={'Unit C 선택'}
                        variables={{
                          order: { order: 'ASC' },
                          filters: {
                            unitSchoolType_In: ['high_school', 'middle_school'],
                            unitAId_In: filters?.unitAId_In,
                            unitBId_In: filters?.unitBId_In,
                          },
                        }}
                      />
                      <UnitDPaginatorSearchOverlayTokenField
                        label={'Unit D'}
                        name={'filters.unitDId_In'}
                        placeholder={'Unit D 선택'}
                        variables={{
                          order: { order: 'ASC' },
                          filters: {
                            unitSchoolType_In: ['high_school', 'middle_school'],
                            unitAId_In: filters?.unitAId_In,
                            unitBId_In: filters?.unitBId_In,
                            unitCId_In: filters?.unitCId_In,
                          },
                        }}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'제작자'}>
                      <UserPaginatorSearchOverlayTokenField
                        label={'제작자'}
                        name={'filters.createdById_In'}
                        placeholder={'제작자 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'정답'}>
                      <EnumPairCheckboxGroupField
                        typename={'QuizAnswerTypeEnum'}
                        name={'filters.answer_In'}
                        label={'정답'}
                        renderContainer={(children) => (
                          <View sx={{ paddingX: 2 }}>
                            <Grid gapX={2}>{children}</Grid>
                          </View>
                        )}
                        renderOptionWrapper={(children, { id }) => (
                          <Grid.Unit key={id} size={'min'}>
                            {children}
                          </Grid.Unit>
                        )}
                      />
                    </QueryFormik.FilterGridUnit>
                  </QueryFormik.FilterGrid>
                </View>
                <View sx={{ marginTop: 5 }}>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<quiz_quizzesQuery>>
                        {(queryReference) => (
                          <QuizWindowPaginator
                            fragmentReference={queryReference}
                            onLoadPage={(page) => setParsedUrlQuery({ ...filters, order, page })}
                          >
                            {({ quizzes }, { renderPagination }) => (
                              <>
                                <View>
                                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                    총 {numberWithCommas(quizzes.totalCount || 0)}개
                                  </Text>
                                </View>
                                <View sx={{ marginTop: 3 }}>
                                  <QuizConnectionDataTable
                                    quizConnection={quizzes}
                                    onRowClick={({ id }) => {
                                      const pathname = `/quiz/${id}`;
                                      router.push({ pathname, query: initialValues }, pathname);
                                    }}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState
                                          title={'필터에 맞는 결과가 없어요'}
                                          description={'다른 필터로 다시 시도해보세요.'}
                                        />
                                      </View>
                                    }
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.()}
                                </View>
                              </>
                            )}
                          </QuizWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            );
          }}
        </QueryFormik>
      </ErrorBoundary>
    </View>
  );
};

Quiz.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
Quiz.authenticated = true;
Quiz.routes = [
  { id: 'quiz', icon: FileIcon, pathname: '/quiz', name: 'OX 퀴즈', permissions: ['quiz_read'] },
  ...QuizId.routes,
];

export default Quiz;
