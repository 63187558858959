import { ImageIcon } from '@primer/octicons-react';
import { FormValidationStatus } from '@primer/react/lib/utils/types/FormValidationStatus';
import { Space } from '@teamturing/react-kit';
import { editor } from 'monaco-editor';
import { ChangeEventHandler, forwardRef, Ref, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import useToast from '../../../hooks/useToast';
import { UploadMetaDataSchema } from '../../../types/upload';
import { getUploadMetaData, uploadGeneral } from '../../../utils/file';
import { isNullable } from '../../../utils/is';
import { scrollToBottom } from '../../../utils/scroll';
import Card from '../Card';
import EmptyState from '../EmptyState';
import FixedMiniView from '../FixedMiniView';
import Grid from '../Grid';
import Katex from '../Katex';
import KatexEditor, { KatexEditorProps } from '../KatexEditor';
import ScrollMiniKatex from '../ScrollMiniKatex';
import Spinner from '../Spinner';
import Stack from '../Stack';
import Switch from '../Switch';
import Text from '../Text';
import View from '../View';

type Props = {
  name?: string;
  onUploadImage?: (blob: Blob, metadata: UploadMetaDataSchema) => void;
  disabledUploadImage?: boolean;
  emptyStateText?: string;
  mini?: boolean;
  validationStatus?: FormValidationStatus;
  reverse?: boolean;
} & Omit<KatexEditorProps, 'sx'>;

const KatexEditorInput = (
  {
    height = 200,
    value: propValue,
    onChange,
    name = '',
    onUploadImage,
    disabled: propDisabled,
    disabledUploadImage: propsDisabledUploadImage,
    emptyStateText = '타이핑한 내용이 없어요',
    onMount,
    type = 'default',
    readOnly,
    mini = false,
    validationStatus,
    reverse = false,
    ...props
  }: Props,
  ref: Ref<editor.IStandaloneCodeEditor>,
) => {
  const INPUT_ID = `katex_editor_${name}`;

  const isScrolled = type === 'scroll';

  const [autoScroll, setAutoScroll] = useState(isScrolled);
  const [value, setValue] = useState<string>(propValue || '');
  useEffect(() => {
    setValue(propValue || '');
  }, [propValue]);
  const katexWrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (autoScroll && !isNullable(katexWrapperRef.current)) scrollToBottom(katexWrapperRef.current, {});
  }, [katexWrapperRef.current?.scrollHeight]);

  const handleChange: KatexEditorProps['onChange'] = (value, ev) => {
    setValue(value || '');
    onChange?.(value, ev);
  };

  const { toast } = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);

  const disabled = propDisabled || isLoading;
  const disabledUploadImage = propsDisabledUploadImage || readOnly || disabled;

  const handleCommonError = () => toast('오류가 발생했어요. 잠시 후 다시 시도해주세요.', 'error');
  const handleLargeFileError = () => toast('이미지의 용량이 너무 커요.', 'error');

  const handleEditorMount: KatexEditorProps['onMount'] = (editor, monaco) => {
    onMount?.(editor, monaco);
    editorRef.current = editor;
  };

  const handleImageUpload: ChangeEventHandler<HTMLInputElement> = async (e) => {
    setIsLoading(true);

    const targetFile = e.target.files?.[0];
    if (!targetFile) {
      setIsLoading(false);
      return;
    }

    const { name: fileName, size: fileSize } = targetFile;
    const metaData = await getUploadMetaData({ fileName, fileSize });

    if (!metaData) {
      setIsLoading(false);
      return;
    }

    const isLargeFile = 'upload_id' in metaData && 'part_metadata' in metaData;
    if (isLargeFile) {
      handleLargeFileError();
      return;
    }

    const response = await uploadGeneral(targetFile, metaData);
    if (response.ok) {
      const { object_url: objectUrl } = metaData;
      const imageTagString = `<img src="${objectUrl}" alt="${objectUrl}" />`;

      const cursorPosition = editorRef.current?.getPosition();

      editorRef.current?.executeEdits(null, [
        {
          range: {
            startLineNumber: cursorPosition?.lineNumber || 1,
            startColumn: cursorPosition?.column || 1,
            endLineNumber: cursorPosition?.lineNumber || 1,
            endColumn: cursorPosition?.column || 1,
          },
          text: `${imageTagString}`,
          forceMoveMarkers: true,
        },
      ]);

      editorRef.current?.focus();

      onUploadImage?.(targetFile, metaData);
    } else {
      handleCommonError();
    }

    e.target.value = '';

    setIsLoading(false);
  };

  /**
   * 렌더링된 Katex의 수식을 누르면 에디터에서 해당 수식으로 커서가 이동하도록 합니다.
   *
   * 에디터 텍스트에 $로 감싸진 부분의 갯수 및 순서는, 렌더링된 Katex의 수식의 갯수 및 순서와 같다는 점에서 착안해 구현했습니다.
   */
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      /**
       * 에디터에서 모든 수식 부분을 찾습니다.
       */
      const matches = editorRef.current?.getModel()?.findMatches('\\$((.|\\n)*?)\\$', true, true, true, null, true);

      /**
       * Katex 렌더러에서 모든 수식에 해당하는 DOM 노드를 찾습니다.
       */
      const katexList = katexWrapperRef.current?.querySelectorAll('.katex');

      /**
       * Katex 렌더러에서 모든 수식에 해당하는 DOM 노드를 돌면서, 클릭된 위치가 어떤 수식에 해당하는지 찾습니다.
       * 찾은 수식에 해당하는 에디터의 커서 위치로 이동합니다.
       */
      katexList?.forEach((node, index) => {
        if (node.contains(e.target as Node)) {
          const match = matches?.[index];
          if (match) {
            editorRef.current?.setSelection(match.range);
            editorRef.current?.revealPositionInCenter(match.range.getStartPosition());
            editorRef.current?.focus();
          }
        }
      });
    };

    if (katexWrapperRef) {
      katexWrapperRef.current?.addEventListener('click', handleClick);
      return () => {
        katexWrapperRef.current?.removeEventListener('click', handleClick);
      };
    }
  }, [katexWrapperRef.current]);

  return (
    <Grid gapX={2} wrap={false} reverse={reverse}>
      <Grid.Unit size={mini ? 'max' : 1 / 2}>
        <Card
          sx={{
            'height': isScrolled ? height : '100%',
            'minHeight': height,
            ...(validationStatus
              ? {
                  borderColor:
                    validationStatus === 'error'
                      ? 'danger.fg'
                      : validationStatus === 'warning'
                      ? 'attention.fg'
                      : 'success.fg',
                }
              : {}),
            ':focus-within': {
              borderColor: 'accent.fg',
            },
            'display': 'flex',
            'flexDirection': 'column',
          }}
        >
          <KatexEditor
            ref={ref}
            sx={{ border: 'none', flex: 1 }}
            height={'auto'}
            type={type}
            value={value}
            onChange={handleChange}
            onMount={handleEditorMount}
            disabled={disabled}
            readOnly={readOnly}
            {...props}
          />
          <View
            sx={{
              backgroundColor: 'canvas.default',
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'transparent',
              borderTopColor: 'border.default',
            }}
          >
            <Grid>
              <Grid.Unit size={'max'}>
                <label htmlFor={INPUT_ID}>
                  <View
                    sx={{
                      paddingX: 3,
                      paddingY: 2,
                      cursor: disabledUploadImage ? 'not-allowed' : 'pointer',
                      color: disabledUploadImage ? 'fg.subtle' : 'fg.default',
                    }}
                  >
                    <Grid sx={{ alignItems: 'center' }}>
                      <Grid.Unit size={'max'}>
                        <Stack gapX={1}>
                          <Stack.Item>
                            <ImageIcon />
                          </Stack.Item>
                          <Stack.Item>
                            <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>이미지 첨부</Text>
                          </Stack.Item>
                        </Stack>
                      </Grid.Unit>
                      <Grid.Unit size={'min'}>{isLoading ? <Spinner size={'small'} /> : null}</Grid.Unit>
                    </Grid>
                  </View>
                </label>
                <StyledInput
                  id={INPUT_ID}
                  accept={'image/*'}
                  tabIndex={-1}
                  disabled={disabledUploadImage}
                  onChange={handleImageUpload}
                />
              </Grid.Unit>
              {isScrolled ? (
                <Grid.Unit size={'min'}>
                  <View sx={{ display: 'flex', alignItems: 'center', paddingRight: 2, height: '100%' }}>
                    <Switch value={autoScroll} disabled={disabled} onChange={(value) => setAutoScroll(value)} />
                  </View>
                </Grid.Unit>
              ) : null}
            </Grid>
          </View>
        </Card>
      </Grid.Unit>
      {mini ? (
        <Grid.Unit size={'min'}>
          <Card sx={{ width: 'fit-content', height: isScrolled ? undefined : '100%' }}>
            {value ? (
              <ScrollMiniKatex ref={katexWrapperRef} height={isScrolled ? undefined : '100%'}>
                {value}
              </ScrollMiniKatex>
            ) : (
              <FixedMiniView>
                <Space py={4}>
                  <EmptyState title={emptyStateText} />
                </Space>
              </FixedMiniView>
            )}
          </Card>
        </Grid.Unit>
      ) : (
        <Grid.Unit size={1 / 2}>
          <Card ref={katexWrapperRef} sx={{ height: isScrolled ? height : '100%', overflow: 'scroll' }}>
            {value ? (
              <Katex>{value}</Katex>
            ) : (
              <View sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <EmptyState title={emptyStateText} />
              </View>
            )}
          </Card>
        </Grid.Unit>
      )}
    </Grid>
  );
};

const StyledInput = styled.input.attrs({ type: 'file' })`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

export default forwardRef(KatexEditorInput);
export type { Props as KatexEditorInputProps };
