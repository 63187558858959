import { SearchIcon, SyncIcon, TagIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import React, { ComponentProps, Suspense } from 'react';
import { graphql } from 'react-relay';

import Button from '../../components/core/Button';
import DialogButton from '../../components/core/DialogButton';
import EmptyState from '../../components/core/EmptyState';
import EnumPairSearchOverlayTokenField from '../../components/core/EnumPairSearchOverlayTokenField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import ProductConnectionDataTable from '../../components/product/ProductConnectionDataTable';
import ProductCreateDialog from '../../components/product/ProductCreateDialog';
import ProductWindowPaginator from '../../components/product/ProductWindowPaginator';
import StoreStuffPaginatorSearchOverlayTokenField from '../../components/storeStuff/StoreStuffPaginatorSearchOverlayTokenField';
import UserPaginatorSearchOverlayTokenField from '../../components/user/UserPaginatorSearchOverlayTokenField';
import { usePaginationContext } from '../../contexts/PaginationContext';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useToast from '../../hooks/useToast';
import { ProductShowTypeEnum } from '../../relay/__generated__/ProductCreateDialog_productCreateMutation.graphql';
import { product_productsQuery, ProductStatusEnum } from '../../relay/__generated__/product_productsQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { NextPage } from '../_app';

import ProductId from './[productId]';

const productsForProduct = graphql`
  query product_productsQuery($filters: ProductFilter, $order: ProductOrder, $page: Int, $pageSize: Int) {
    ...ProductWindowPaginator_query @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

type Props = {};

const Product: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();
  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    order: { type: 'string' },
    page: { type: 'number' },

    status_In: { type: 'string', multiple: true },
    createdById_In: { type: 'string', multiple: true },
    storeStuffId_In: { type: 'string', multiple: true },
    showTypes_Contains: { type: 'string', multiple: true },
  });
  const { search, order, page, status_In, createdById_In, storeStuffId_In, showTypes_Contains } = initialValues;

  const { pageSize } = usePaginationContext();

  return (
    <View>
      <Head siteTitle={'판매 상품'} />
      <ErrorBoundary>
        <QueryFormik<product_productsQuery>
          query={productsForProduct}
          staticVariables={{ pageSize }}
          initialValues={{
            filters: {
              search,
              status_In: status_In as ProductStatusEnum[],
              createdById_In,
              storeStuffId_In,
              showTypes_Contains: showTypes_Contains as ProductShowTypeEnum[],
            },
            order: order || '-modified',
            page,
          }}
          options={{ fetchPolicy: 'store-and-network' }}
          onSubmit={(values) => setParsedUrlQuery({ ...values.filters, order }, { scroll: false })}
          enableReinitialize
        >
          {({ values: { filters, order }, handleSubmit, resetForm, setFieldValue, submitForm }, queryReference) => {
            const handleReset = () => {
              resetForm({ values: { order } });
              setParsedUrlQuery({ order }, { scroll: false });
            };

            const handleChangeOrderActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
              newValue,
            ) => {
              setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
            };

            return (
              <View>
                <Grid sx={{ alignItems: 'center' }}>
                  <Grid.Unit size={'max'}>
                    <Text as={'h1'}>판매 상품</Text>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <DialogButton
                      size={'large'}
                      renderDialog={({ isOpen, closeDialog }) => (
                        <ProductCreateDialog
                          isOpen={isOpen}
                          onDismiss={closeDialog}
                          wide
                          config={{
                            onCompleted: () => {
                              toast('상품 등록이 완료됐어요!', 'success');
                              handleSubmit();
                              closeDialog();
                            },
                            onError: () => {
                              toast('다시 등록해 주세요', 'error');
                            },
                          }}
                        />
                      )}
                      variant={'primary'}
                    >
                      등록하기
                    </DialogButton>
                  </Grid.Unit>
                </Grid>
                <Grid>
                  <Grid.Unit size={'min'}>
                    <QueryFormik.FilterSearchTextField
                      typename={'ProductFilter'}
                      label={'Search'}
                      labelConfig={{ visuallyHidden: true }}
                      name={'filters.search'}
                      size={'large'}
                      autoComplete={'off'}
                      leadingVisual={SearchIcon}
                      debounce
                      onChange={(e) => {
                        setFieldValue('filters.search', e.target.value);
                        setTimeout(() => submitForm(), 0);
                      }}
                    />
                  </Grid.Unit>
                  <Grid.Unit size={'max'}>
                    <View sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Stack gapX={2}>
                        <Stack.Item>
                          <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleReset}>
                            초기화
                          </Button>
                        </Stack.Item>
                        <Stack.Item>
                          <QueryFormik.OrderActionMenuButton
                            typename={'ProductOrder'}
                            orders={['modified']}
                            onChange={handleChangeOrderActionMenu}
                          />
                        </Stack.Item>
                      </Stack>
                    </View>
                  </Grid.Unit>
                </Grid>
                <View sx={{ marginTop: 5 }}>
                  <QueryFormik.FilterGrid
                    onChange={(filters) => {
                      setParsedUrlQuery({ ...filters, order }, { scroll: false });
                    }}
                  >
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'상태'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'ProductStatusEnum'}
                        label={'상태'}
                        name={'filters.status_In'}
                        placeholder={'상태 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'제작자'}>
                      <UserPaginatorSearchOverlayTokenField
                        label={'제작자'}
                        placeholder={'제작자 선택'}
                        name={'filters.createdById_In'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'상품'}>
                      <StoreStuffPaginatorSearchOverlayTokenField
                        label={'상품'}
                        placeholder={'상품 선택'}
                        name={'filters.storeStuffId_In'}
                        variables={{
                          order: {
                            created: 'DESC',
                          },
                        }}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'타입'}>
                      <EnumPairSearchOverlayTokenField
                        label={'타입'}
                        placeholder={'타입 선택'}
                        name={'filters.showTypes_Contains'}
                        typename={'ProductShowTypeEnum'}
                      />
                    </QueryFormik.FilterGridUnit>
                  </QueryFormik.FilterGrid>
                </View>
                <View sx={{ marginTop: 5 }}>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<product_productsQuery>>
                        {(queryReference) => (
                          <ProductWindowPaginator
                            fragmentReference={queryReference}
                            onLoadPage={(page) => setParsedUrlQuery({ ...filters, order, page })}
                          >
                            {({ products }, { renderPagination }) => (
                              <>
                                <View>
                                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                    총 {numberWithCommas(products?.totalCount || 0)}건
                                  </Text>
                                </View>
                                <View sx={{ marginTop: 3 }}>
                                  <ProductConnectionDataTable
                                    productConnection={products}
                                    onRowClick={({ id }) => router.push({ pathname: `/product/${id}` })}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState
                                          title={'필터에 맞는 결과가 없어요'}
                                          description={'다른 필터로 다시 시도해보세요.'}
                                        />
                                      </View>
                                    }
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.()}
                                </View>
                              </>
                            )}
                          </ProductWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            );
          }}
        </QueryFormik>
      </ErrorBoundary>
    </View>
  );
};

Product.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
Product.authenticated = true;
Product.routes = [
  {
    id: 'product',
    icon: TagIcon,
    pathname: '/product',
    name: '판매 상품',
    permissions: ['product_read'],
  },
  ...ProductId.routes,
];

export default Product;
