import { PackageIcon, SearchIcon, SyncIcon, TriangleDownIcon, XIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import React, { ComponentProps, Suspense, useEffect, useState } from 'react';
import { graphql } from 'react-relay';

import ActionList from '../../components/core/ActionList';
import ActionMenu from '../../components/core/ActionMenu';
import Button from '../../components/core/Button';
import Checkbox from '../../components/core/Checkbox';
import CheckboxGroupField from '../../components/core/CheckboxGroupField';
import { RowWrapper } from '../../components/core/DataTable';
import DateRangeField from '../../components/core/DateRangeField';
import DialogButton from '../../components/core/DialogButton';
import EmptyState from '../../components/core/EmptyState';
import EnumPairCheckboxGroupField from '../../components/core/EnumPairCheckboxGroupField';
import EnumPairSearchOverlayTokenField from '../../components/core/EnumPairSearchOverlayTokenField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import IconButton from '../../components/core/IconButton';
import ItemList from '../../components/core/ItemList';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import NumberRangeField from '../../components/core/NumberRangeField';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import TextField from '../../components/core/TextField';
import View from '../../components/core/View';
import EbookSearchOverlayTokenField from '../../components/ebook/EbookSearchOverlayTokenField';
import ScrapSourceBookNamesSearchOverlayField from '../../components/scrapSource/ScrapSourceBookNamesSearchOverlayField';
import TaskAssignDialog from '../../components/task/TaskAssignDialog';
import TaskConnectionDataTable from '../../components/task/TaskConnectionDataTable';
import TaskCreateDialog from '../../components/task/TaskCreateDialog';
import TaskImageExportDialog from '../../components/task/TaskImageExportDialog';
import TaskStatusTabList from '../../components/task/TaskStatusTabList';
import TaskWindowPaginator from '../../components/task/TaskWindowPaginator';
import UnitAPaginatorSearchOverlayTokenField from '../../components/unitA/UnitAPaginatorSearchOverlayTokenField';
import UnitBPaginatorSearchOverlayTokenField from '../../components/unitB/UnitBPaginatorSearchOverlayTokenField';
import UnitCPaginatorSearchOverlayTokenField from '../../components/unitC/UnitCPaginatorSearchOverlayTokenField';
import UnitDPaginatorSearchOverlayTokenField from '../../components/unitD/UnitDPaginatorSearchOverlayTokenField';
import UserPaginatorSearchOverlayTokenField from '../../components/user/UserPaginatorSearchOverlayTokenField';
import { usePaginationContext } from '../../contexts/PaginationContext';
import useAlert from '../../hooks/useAlert';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import {
  task_CourseTitle_courseQuery,
  task_CourseTitle_courseQuery$variables,
} from '../../relay/__generated__/task_CourseTitle_courseQuery.graphql';
import { task_coursesQuery } from '../../relay/__generated__/task_coursesQuery.graphql';
import {
  ScrapSourceExamCategoryEnum,
  ScrapSourceExamGradeEnum,
  ScrapSourceExamMonthEnum,
  ScrapSourceExamSourceEnum,
  ScrapSourceExamYearEnum,
  task_tasksQuery,
  TaskErrorTypeEnum,
  TaskProblemAnswerTypeEnum,
  TaskProblemCreationTypeEnum,
  TaskProblemTypeEnum,
  TaskStatusEnum,
  VideoTypeEnum,
} from '../../relay/__generated__/task_tasksQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { normalizeObject } from '../../utils/object';
import { NextPage } from '../_app';

import TaskId from './[taskId]';

const tasksForTask = graphql`
  query task_tasksQuery($filters: TaskFilter, $order: TaskOrder, $page: Int, $pageSize: Int) {
    ...TaskWindowPaginator_query @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

type Props = {};

const Task: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();
  const { alert } = useAlert();

  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    courseId_Exact: { type: 'string' },

    search: { type: 'string' },
    status_Exact: { type: 'string' },
    problemType_In: { type: 'string', multiple: true },
    ebook_In: { type: 'string', multiple: true },
    problemGenerator_Exists_In: { type: 'boolean', multiple: true },
    problemCreationType_In: { type: 'string', multiple: true },
    answerType_In: { type: 'string', multiple: true },
    image_Exists_In: { type: 'boolean', multiple: true },

    scrapSourceCategory_In: { type: 'string', multiple: true },
    scrapSourceGrade_In: { type: 'string', multiple: true },
    scrapSourceMonth_In: { type: 'string', multiple: true },
    scrapSourceSource_In: { type: 'string', multiple: true },
    scrapSourceYear_In: { type: 'string', multiple: true },
    scrapSourceBookName_Contains: { type: 'string' },
    scrapSourceUnitAId_In: { type: 'string', multiple: true },
    scrapSourceBookPublisher_Contains: { type: 'string' },

    unitAId_In: { type: 'string', multiple: true },
    unitBId_In: { type: 'string', multiple: true },
    unitCId_In: { type: 'string', multiple: true },
    unitDId_In: { type: 'string', multiple: true },
    lastUnitDId_In: { type: 'string', multiple: true },
    difficulty_Gte: { type: 'number' },
    difficulty_Lte: { type: 'number' },

    assignedTo_Exists_In: { type: 'boolean', multiple: true },
    assignedToId_In: { type: 'string', multiple: true },

    status_In: { type: 'string', multiple: true },
    completedById_In: { type: 'string', multiple: true },
    completed_Gte: { type: 'string' },
    completed_Lte: { type: 'string' },

    completedById_Exclude_In: { type: 'string', multiple: true },

    solutionVideo_Exists_In: { type: 'boolean', multiple: true },
    solutionVideoVideoType_In: { type: 'string', multiple: true },

    hint_Published_In: { type: 'boolean', multiple: true },
    specialSubmitFeedback_Published_In: { type: 'boolean', multiple: true },
    generalSubmitFeedback_Published_In: { type: 'boolean', multiple: true },

    errorType_In: { type: 'string', multiple: true },

    typingAutomationScript_Exists_In: { type: 'boolean', multiple: true },

    order: { type: 'string' },
    page: { type: 'number' },
  });
  const { pageSize } = usePaginationContext();

  const {
    courseId_Exact,
    search,
    status_Exact,
    problemType_In,
    problemCreationType_In,
    ebook_In,
    problemGenerator_Exists_In,
    answerType_In,
    image_Exists_In,
    scrapSourceCategory_In,
    scrapSourceGrade_In,
    scrapSourceMonth_In,
    scrapSourceSource_In,
    scrapSourceYear_In,
    scrapSourceBookName_Contains,
    scrapSourceUnitAId_In,
    scrapSourceBookPublisher_Contains,
    unitAId_In,
    unitBId_In,
    unitCId_In,
    unitDId_In,
    lastUnitDId_In,
    difficulty_Gte,
    difficulty_Lte,
    assignedTo_Exists_In,
    assignedToId_In,
    status_In,
    completedById_In,
    completed_Gte,
    completed_Lte,
    completedById_Exclude_In,
    solutionVideo_Exists_In,
    solutionVideoVideoType_In,
    hint_Published_In,
    specialSubmitFeedback_Published_In,
    generalSubmitFeedback_Published_In,
    errorType_In,
    typingAutomationScript_Exists_In,
    order,
    page,
  } = initialValues;

  const [selectedTasks, setSelectedTasks] = useState<{ id: string; status: string }[]>([]);
  const isDifferentStatusTasks = selectedTasks.some(({ status }) => selectedTasks[0].status !== status);

  const [{ courses }] = useLazyLoadQuery<task_coursesQuery>(
    graphql`
      query task_coursesQuery {
        courses(order: { year: DESC }) {
          edges {
            node {
              id
              title
            }
          }
        }
      }
    `,
    {},
    { fetchPolicy: 'store-or-network' },
  );
  const [{ node: defaultCourse }] = courses.edges;

  useEffect(() => {
    return () => setSelectedTasks([]);
  }, [JSON.stringify(initialValues)]);

  return (
    <View>
      <Head title={'TCMS'} siteTitle={'문제 조회 · 배정'} />
      <ErrorBoundary>
        <QueryFormik<task_tasksQuery>
          query={tasksForTask}
          staticVariables={{ pageSize }}
          initialValues={{
            filters: {
              search,
              courseId_Exact: courseId_Exact || defaultCourse.id,
              status_Exact: status_Exact as TaskStatusEnum,
              problemType_In: problemType_In as TaskProblemTypeEnum[],
              ebook_In,
              problemCreationType_In: problemCreationType_In as TaskProblemCreationTypeEnum[],
              problemGenerator_Exists_In,
              answerType_In: answerType_In as TaskProblemAnswerTypeEnum[],
              image_Exists_In,
              scrapSourceCategory_In: scrapSourceCategory_In as ScrapSourceExamCategoryEnum[],
              scrapSourceGrade_In: scrapSourceGrade_In as ScrapSourceExamGradeEnum[],
              scrapSourceMonth_In: scrapSourceMonth_In as ScrapSourceExamMonthEnum[],
              scrapSourceSource_In: scrapSourceSource_In as ScrapSourceExamSourceEnum[],
              scrapSourceYear_In: scrapSourceYear_In as ScrapSourceExamYearEnum[],
              scrapSourceBookName_Contains,
              scrapSourceUnitAId_In,
              scrapSourceBookPublisher_Contains,
              unitAId_In,
              unitBId_In,
              unitCId_In,
              unitDId_In,
              lastUnitDId_In,
              difficulty_Gte,
              difficulty_Lte,
              assignedTo_Exists_In,
              assignedToId_In,
              status_In: status_In as TaskStatusEnum[],
              completedById_In,
              completed_Gte,
              completed_Lte,
              completedById_Exclude_In,
              solutionVideo_Exists_In,
              solutionVideoVideoType_In: solutionVideoVideoType_In as VideoTypeEnum[],
              hint_Published_In,
              specialSubmitFeedback_Published_In,
              generalSubmitFeedback_Published_In,
              errorType_In: errorType_In as TaskErrorTypeEnum[],
              typingAutomationScript_Exists_In,
            },
            order: order || '-sequence',
            page,
          }}
          options={{ fetchPolicy: 'store-and-network' }}
          onSubmit={(values) => setParsedUrlQuery({ ...values.filters, order }, { scroll: false })}
          enableReinitialize
        >
          {({ values: { filters, order }, setFieldValue, submitForm, handleSubmit, resetForm }, queryReference) => {
            const handleReset = () => {
              resetForm({
                values: {
                  order,
                  filters: { courseId_Exact: filters?.courseId_Exact, status_Exact: filters?.status_Exact },
                },
              });
              setParsedUrlQuery(
                { order, courseId_Exact: filters?.courseId_Exact, status_Exact: filters?.status_Exact },
                { scroll: false },
              );
            };

            const handleChangeOrderActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
              newValue,
            ) => {
              setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
            };

            const isSelectedRatingRange = filters?.difficulty_Gte || filters?.difficulty_Lte;
            const isSelectedCompletedPeriod = filters?.completed_Lte || filters?.completed_Gte;

            return (
              <View>
                <Grid sx={{ alignItems: 'center' }} gapX={1}>
                  <Grid.Unit size={'min'}>
                    <Text as={'h1'}>문제 조회 · 배정</Text>
                  </Grid.Unit>
                  <Grid.Unit size={'max'}>
                    <ActionMenu>
                      <ActionMenu.Anchor>
                        <Button
                          trailingIcon={TriangleDownIcon}
                          variant={'plain'}
                          sx={{ '& span': { marginRight: '0px !important' } }}
                        >
                          {filters?.courseId_Exact ? (
                            <Suspense fallback={'-'}>
                              <CourseTitle id={filters?.courseId_Exact} />
                            </Suspense>
                          ) : null}
                        </Button>
                      </ActionMenu.Anchor>
                      <ActionMenu.Overlay>
                        <ActionList selectionVariant={'single'}>
                          <ItemList
                            items={courses.edges.map(({ node }) => node)}
                            renderItem={({ id, title }) => (
                              <ActionList.Item
                                key={id}
                                selected={id === filters?.courseId_Exact}
                                onSelect={() => {
                                  setFieldValue('filters.courseId_Exact', id);
                                }}
                              >
                                {title}
                              </ActionList.Item>
                            )}
                          />
                        </ActionList>
                      </ActionMenu.Overlay>
                    </ActionMenu>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <Stack gapX={2}>
                      <Stack.Item>
                        <DialogButton
                          size={'large'}
                          renderDialog={({ isOpen, closeDialog }) => (
                            <TaskImageExportDialog isOpen={isOpen} onDismiss={closeDialog} wide />
                          )}
                        >
                          이미지 추출하기
                        </DialogButton>
                      </Stack.Item>
                      <Stack.Item>
                        <DialogButton
                          size={'large'}
                          renderDialog={({ isOpen, closeDialog }) => {
                            return (
                              <TaskCreateDialog
                                isOpen={isOpen}
                                onDismiss={closeDialog}
                                config={{
                                  onCompleted: () => {
                                    toast('문제 생성에 성공했어요', 'success');
                                    closeDialog();
                                    handleSubmit();
                                  },
                                  onError: () => {
                                    toast('문제 생성에 실패했어요', 'error');
                                  },
                                }}
                              />
                            );
                          }}
                        >
                          생성하기
                        </DialogButton>
                      </Stack.Item>
                      {isDifferentStatusTasks ? (
                        <Stack.Item>
                          <Button
                            size={'large'}
                            variant={'primary'}
                            disabled={selectedTasks.length === 0}
                            onClick={() => alert('같은 상태의 문제만 배정할 수 있어요')}
                          >
                            배정하기
                          </Button>
                        </Stack.Item>
                      ) : (
                        <Stack.Item>
                          <DialogButton
                            size={'large'}
                            variant={'primary'}
                            disabled={selectedTasks.length === 0}
                            renderDialog={({ isOpen, closeDialog }) => (
                              <TaskAssignDialog
                                isOpen={isOpen}
                                onDismiss={closeDialog}
                                tasks={selectedTasks.map(({ id }) => id)}
                                config={{
                                  onCompleted: () => {
                                    toast('배정 성공했어요', 'success');
                                    closeDialog();
                                    handleSubmit();
                                    setSelectedTasks([]);
                                  },
                                  onError: () => {
                                    toast('배정 실패했어요', 'error');
                                  },
                                }}
                              />
                            )}
                          >
                            배정하기
                          </DialogButton>
                        </Stack.Item>
                      )}
                    </Stack>
                  </Grid.Unit>
                </Grid>
                <Suspense>
                  <TaskStatusTabList
                    selectedId={status_Exact}
                    onSelect={({ value }) => {
                      setParsedUrlQuery({ ...filters, status_Exact: value, order });
                    }}
                    variables={{ filters: { courseId_Exact: filters?.courseId_Exact } }}
                  />
                </Suspense>
                <View sx={{ marginTop: 5 }}>
                  <Grid>
                    <Grid.Unit size={'min'}>
                      <QueryFormik.FilterSearchTextField
                        typename={'TaskFilter'}
                        label={'Search'}
                        labelConfig={{ visuallyHidden: true }}
                        name={'filters.search'}
                        size={'large'}
                        autoComplete={'off'}
                        leadingVisual={SearchIcon}
                        debounce
                        onChange={(e) => {
                          setFieldValue('filters.search', e.target.value);
                          setTimeout(() => submitForm(), 0);
                        }}
                      />
                    </Grid.Unit>
                    <Grid.Unit size={'max'}>
                      <View sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Stack gapX={2}>
                          <Stack.Item>
                            <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleReset}>
                              초기화
                            </Button>
                          </Stack.Item>
                          <Stack.Item>
                            <QueryFormik.OrderActionMenuButton
                              typename={'TaskOrder'}
                              orders={['sequence', 'title', 'unitD_Order', 'completed', 'difficulty']}
                              onChange={handleChangeOrderActionMenu}
                            />
                          </Stack.Item>
                        </Stack>
                      </View>
                    </Grid.Unit>
                  </Grid>
                </View>
                <View sx={{ marginTop: 5 }}>
                  <QueryFormik.FilterGrid
                    onChange={(filters) => {
                      setParsedUrlQuery({ ...filters, order }, { scroll: false });
                    }}
                  >
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'창작 방식'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'TaskProblemTypeEnum'}
                        name={'filters.problemType_In'}
                        label={'창작 방식'}
                        placeholder={'창작 방식 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'변형 정도'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'TaskProblemCreationTypeEnum'}
                        name={'filters.problemCreationType_In'}
                        label={'변형 정도'}
                        placeholder={'변형 정도 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'전자책'}>
                      <EbookSearchOverlayTokenField
                        name={'filters.ebook_In'}
                        label={'전자책'}
                        placeholder={'전자책 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'자동 생성기'}>
                      <CheckboxGroupField
                        name={'filters.problemGenerator_Exists_In'}
                        label={'자동 생성기'}
                        options={[
                          { id: 'true', text: '있음' },
                          { id: 'false', text: '없음' },
                        ]}
                        renderContainer={(children) => (
                          <View sx={{ paddingX: 2 }}>
                            <Grid gapX={2}>{children}</Grid>
                          </View>
                        )}
                        renderOptionWrapper={(children, { id }) => (
                          <Grid.Unit key={id} size={'min'}>
                            {children}
                          </Grid.Unit>
                        )}
                        value={filters?.problemGenerator_Exists_In?.map((value) => (value === true ? 'true' : 'false'))}
                        onChange={(selected) =>
                          setFieldValue(
                            'filters.problemGenerator_Exists_In',
                            selected.map((selectedValue) => (selectedValue === 'true' ? true : false)),
                          )
                        }
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'문제 유형'}>
                      <EnumPairCheckboxGroupField
                        typename={'TaskProblemAnswerTypeEnum'}
                        name={'filters.answerType_In'}
                        label={'문제 유형'}
                        renderContainer={(children) => (
                          <View sx={{ paddingX: 2 }}>
                            <Grid gapX={2}>{children}</Grid>
                          </View>
                        )}
                        renderOptionWrapper={(children, { id }) => (
                          <Grid.Unit key={id} size={'min'}>
                            {children}
                          </Grid.Unit>
                        )}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'이미지'}>
                      <CheckboxGroupField
                        name={'filters.image_Exists_In'}
                        label={'이미지'}
                        options={[
                          { id: 'true', text: '있음' },
                          { id: 'false', text: '없음' },
                        ]}
                        renderContainer={(children) => (
                          <View sx={{ paddingX: 2 }}>
                            <Grid gapX={2}>{children}</Grid>
                          </View>
                        )}
                        renderOptionWrapper={(children, { id }) => (
                          <Grid.Unit key={id} size={'min'}>
                            {children}
                          </Grid.Unit>
                        )}
                        value={filters?.image_Exists_In?.map((value) => (value === true ? 'true' : 'false'))}
                        onChange={(selected) =>
                          setFieldValue(
                            'filters.image_Exists_In',
                            selected.map((selectedValue) => (selectedValue === 'true' ? true : false)),
                          )
                        }
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={1} title={'기출 상세'}>
                      <Grid>
                        <Grid.Unit size={[1, 1, 1 / 5]}>
                          <EnumPairSearchOverlayTokenField
                            typename={'ScrapSourceExamSourceEnum'}
                            label={'출제기관'}
                            labelConfig={{ visuallyHidden: true }}
                            name={'filters.scrapSourceSource_In'}
                            placeholder={'출제기관 선택'}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={[1, 1, 1 / 5]}>
                          <EnumPairSearchOverlayTokenField
                            typename={'ScrapSourceExamYearEnum'}
                            label={'연도'}
                            labelConfig={{ visuallyHidden: true }}
                            name={'filters.scrapSourceYear_In'}
                            placeholder={'연도 선택'}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={[1, 1, 1 / 5]}>
                          <EnumPairSearchOverlayTokenField
                            typename={'ScrapSourceExamMonthEnum'}
                            label={'월'}
                            labelConfig={{ visuallyHidden: true }}
                            name={'filters.scrapSourceMonth_In'}
                            placeholder={'월 선택'}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={[1, 1, 1 / 5]}>
                          <EnumPairSearchOverlayTokenField
                            typename={'ScrapSourceExamGradeEnum'}
                            label={'학년'}
                            labelConfig={{ visuallyHidden: true }}
                            name={'filters.scrapSourceGrade_In'}
                            placeholder={'학년 선택'}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={[1, 1, 1 / 5]}>
                          <EnumPairSearchOverlayTokenField
                            typename={'ScrapSourceExamCategoryEnum'}
                            label={'구분'}
                            labelConfig={{ visuallyHidden: true }}
                            name={'filters.scrapSourceCategory_In'}
                            placeholder={'구분 선택'}
                          />
                        </Grid.Unit>
                      </Grid>
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={1} title={'사설 상세'}>
                      <Grid sx={{ alignItems: 'center' }}>
                        <Grid.Unit size={[1, 1, 1 / 5]}>
                          <ScrapSourceBookNamesSearchOverlayField
                            label={'출처'}
                            labelConfig={{ visuallyHidden: true }}
                            name={'filters.scrapSourceBookName_Contains'}
                            placeholder={'출처 검색'}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={[1, 1, 1 / 5]}>
                          <UnitAPaginatorSearchOverlayTokenField
                            label={'unitA'}
                            labelConfig={{ visuallyHidden: true }}
                            name={'filters.scrapSourceUnitAId_In'}
                            placeholder={'Unit A 선택'}
                            variables={{
                              filters: { courseId_In: [filters!.courseId_Exact!] },
                              order: { order: 'ASC' },
                            }}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={[1, 1, 1 / 5]}>
                          <TextField
                            label={'출판사'}
                            labelConfig={{ visuallyHidden: true }}
                            name={'filters.scrapSourceBookPublisher_Contains'}
                            placeholder={'출판사 검색'}
                            trailingVisual={SearchIcon}
                            debounce
                          />
                        </Grid.Unit>
                      </Grid>
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={1} title={'Unit'}>
                      <Grid sx={{ alignItems: 'center' }}>
                        <Grid.Unit size={[1, 1, 1 / 5]}>
                          <UnitAPaginatorSearchOverlayTokenField
                            label={'Unit A'}
                            labelConfig={{ visuallyHidden: true }}
                            name={'filters.unitAId_In'}
                            placeholder={'Unit A 선택'}
                            variables={{
                              filters: { courseId_In: [filters!.courseId_Exact!] },
                              order: { order: 'ASC' },
                            }}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={[1, 1, 1 / 5]}>
                          <UnitBPaginatorSearchOverlayTokenField
                            label={'Unit B'}
                            labelConfig={{ visuallyHidden: true }}
                            name={'filters.unitBId_In'}
                            placeholder={'Unit B 선택'}
                            variables={{
                              order: { order: 'ASC' },
                              filters: {
                                courseId_In: [filters!.courseId_Exact!],
                                unitAId_In: filters?.unitAId_In,
                              },
                            }}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={[1, 1, 1 / 5]}>
                          <UnitCPaginatorSearchOverlayTokenField
                            label={'Unit C'}
                            labelConfig={{ visuallyHidden: true }}
                            name={'filters.unitCId_In'}
                            placeholder={'Unit C 선택'}
                            variables={{
                              order: { order: 'ASC' },
                              filters: {
                                courseId_In: [filters!.courseId_Exact!],
                                unitAId_In: filters?.unitAId_In,
                                unitBId_In: filters?.unitBId_In,
                              },
                            }}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={[1, 1, 1 / 5]}>
                          <UnitDPaginatorSearchOverlayTokenField
                            label={'Unit D'}
                            labelConfig={{ visuallyHidden: true }}
                            name={'filters.unitDId_In'}
                            placeholder={'Unit D 선택'}
                            variables={{
                              order: { order: 'ASC' },
                              filters: {
                                courseId_In: [filters!.courseId_Exact!],
                                unitAId_In: filters?.unitAId_In,
                                unitBId_In: filters?.unitBId_In,
                                unitCId_In: filters?.unitCId_In,
                              },
                            }}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={[1, 1, 1 / 5]}>
                          <UnitDPaginatorSearchOverlayTokenField
                            label={'last Unit D'}
                            labelConfig={{ visuallyHidden: true }}
                            name={'filters.lastUnitDId_In'}
                            placeholder={'Last Unit D 선택'}
                            variables={{
                              order: { order: 'ASC' },
                              filters: {
                                courseId_In: [filters!.courseId_Exact!],
                                unitAId_In: filters?.unitAId_In,
                                unitBId_In: filters?.unitBId_In,
                                unitCId_In: filters?.unitCId_In,
                              },
                            }}
                          />
                        </Grid.Unit>
                      </Grid>
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'Rating'}>
                      <Grid sx={{ alignItems: 'center' }} wrap={false}>
                        <Grid.Unit size={'max'}>
                          <NumberRangeField
                            label={'Rating'}
                            labelConfig={{ visuallyHidden: true }}
                            name={['filters.difficulty_Gte', 'filters.difficulty_Lte']}
                            placeholder={['0', '1000']}
                            min={0}
                            max={1000}
                            step={1}
                            debounce
                          />
                        </Grid.Unit>
                        <Grid.Unit size={'min'}>
                          <IconButton
                            icon={XIcon}
                            aria-label={'reset range'}
                            variant={'plain'}
                            disabled={!isSelectedRatingRange}
                            onClick={() => {
                              setFieldValue('filters.difficulty_Gte', undefined);
                              setFieldValue('filters.difficulty_Lte', undefined);
                            }}
                          />
                        </Grid.Unit>
                      </Grid>
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'배정'}>
                      <CheckboxGroupField
                        name={'filters.assignedTo_Exists_In'}
                        label={'배정'}
                        options={[
                          { id: 'false', text: '배정 필요' },
                          { id: 'true', text: '배정 완료' },
                        ]}
                        renderContainer={(children) => (
                          <View sx={{ paddingX: 2 }}>
                            <Grid gapX={2}>{children}</Grid>
                          </View>
                        )}
                        renderOptionWrapper={(children, { id }) => (
                          <Grid.Unit key={id} size={'min'}>
                            {children}
                          </Grid.Unit>
                        )}
                        value={filters?.assignedTo_Exists_In?.map((value) => (value === true ? 'true' : 'false'))}
                        onChange={(selected) =>
                          setFieldValue(
                            'filters.assignedTo_Exists_In',
                            selected.map((selectedValue) => selectedValue === 'true'),
                          )
                        }
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'작업자'}>
                      <UserPaginatorSearchOverlayTokenField
                        label={'작업자'}
                        name={'filters.assignedToId_In'}
                        placeholder={'작업자 선택'}
                        variables={{ filters: { team_In: ['contents'] } }}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'완료 상태'}>
                      <EnumPairSearchOverlayTokenField
                        label={'완료 상태'}
                        typename={'TaskStatusEnum'}
                        name={'filters.status_In'}
                        placeholder={'완료 상태 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'완료자'}>
                      <UserPaginatorSearchOverlayTokenField
                        label={'완료자'}
                        name={'filters.completedById_In'}
                        placeholder={'완료자 선택'}
                        variables={{ filters: { team_In: ['contents'] } }}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'제외 완료자'}>
                      <UserPaginatorSearchOverlayTokenField
                        label={'제외 완료자'}
                        name={'filters.completedById_Exclude_In'}
                        placeholder={'제외 완료자 선택'}
                        variables={{ filters: { team_In: ['contents'] } }}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'완료일'}>
                      <Grid sx={{ alignItems: 'center' }} wrap={false}>
                        <Grid.Unit size={'max'}>
                          <DateRangeField
                            label={'완료일'}
                            labelConfig={{ visuallyHidden: true }}
                            name={['filters.completed_Gte', 'filters.completed_Lte']}
                            placeholder={['시작일', '종료일']}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={'min'}>
                          <IconButton
                            icon={XIcon}
                            aria-label={'reset completed period'}
                            variant={'plain'}
                            disabled={!isSelectedCompletedPeriod}
                            onClick={() => {
                              setFieldValue('filters', {
                                ...filters,
                                completed_Gte: undefined,
                                completed_Lte: undefined,
                              });
                            }}
                          />
                        </Grid.Unit>
                      </Grid>
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'해설 영상'}>
                      <CheckboxGroupField
                        name={'filters.solutionVideo_Exists_In'}
                        label={'해설 영상'}
                        options={[
                          { id: 'true', text: '있음' },
                          { id: 'false', text: '없음' },
                        ]}
                        renderContainer={(children) => (
                          <View sx={{ paddingX: 2 }}>
                            <Grid gapX={2}>{children}</Grid>
                          </View>
                        )}
                        renderOptionWrapper={(children, { id }) => (
                          <Grid.Unit key={id} size={'min'}>
                            {children}
                          </Grid.Unit>
                        )}
                        value={filters?.solutionVideo_Exists_In?.map((value) => (value === true ? 'true' : 'false'))}
                        onChange={(selected) =>
                          setFieldValue(
                            'filters.solutionVideo_Exists_In',
                            selected.map((selectedValue) => (selectedValue === 'true' ? true : false)),
                          )
                        }
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'해설 영상 타입'}>
                      <EnumPairCheckboxGroupField
                        typename={'VideoTypeEnum'}
                        name={'filters.solutionVideoVideoType_In'}
                        label={'해설 영상 타입'}
                        renderContainer={(children) => (
                          <View sx={{ paddingX: 2 }}>
                            <Grid gapX={2}>{children}</Grid>
                          </View>
                        )}
                        renderOptionWrapper={(children, { id }) => (
                          <Grid.Unit key={id} size={'min'}>
                            {children}
                          </Grid.Unit>
                        )}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'힌트'}>
                      <CheckboxGroupField
                        name={'filters.hint_Published_In'}
                        label={'힌트'}
                        options={[
                          { id: 'true', text: '출시 완료' },
                          { id: 'false', text: '출시 필요' },
                        ]}
                        renderContainer={(children) => (
                          <View sx={{ paddingX: 2 }}>
                            <Grid gapX={2}>{children}</Grid>
                          </View>
                        )}
                        renderOptionWrapper={(children, { id }) => (
                          <Grid.Unit key={id} size={'min'}>
                            {children}
                          </Grid.Unit>
                        )}
                        value={filters?.hint_Published_In?.map((value) => (value === true ? 'true' : 'false'))}
                        onChange={(selected) =>
                          setFieldValue(
                            'filters.hint_Published_In',
                            selected.map((selectedValue) => (selectedValue === 'true' ? true : false)),
                          )
                        }
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'특정 힌트'}>
                      <CheckboxGroupField
                        name={'filters.specialSubmitFeedback_Published_In'}
                        label={'특정'}
                        options={[
                          { id: 'true', text: '출시 완료' },
                          { id: 'false', text: '출시 필요' },
                        ]}
                        renderContainer={(children) => (
                          <View sx={{ paddingX: 2 }}>
                            <Grid gapX={2}>{children}</Grid>
                          </View>
                        )}
                        renderOptionWrapper={(children, { id }) => (
                          <Grid.Unit key={id} size={'min'}>
                            {children}
                          </Grid.Unit>
                        )}
                        value={filters?.specialSubmitFeedback_Published_In?.map((value) =>
                          value === true ? 'true' : 'false',
                        )}
                        onChange={(selected) =>
                          setFieldValue(
                            'filters.specialSubmitFeedback_Published_In',
                            selected.map((selectedValue) => (selectedValue === 'true' ? true : false)),
                          )
                        }
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'일반 힌트'}>
                      <CheckboxGroupField
                        name={'filters.generalSubmitFeedback_Published_In'}
                        label={'일반'}
                        options={[
                          { id: 'true', text: '출시 완료' },
                          { id: 'false', text: '출시 필요' },
                        ]}
                        renderContainer={(children) => (
                          <View sx={{ paddingX: 2 }}>
                            <Grid gapX={2}>{children}</Grid>
                          </View>
                        )}
                        renderOptionWrapper={(children, { id }) => (
                          <Grid.Unit key={id} size={'min'}>
                            {children}
                          </Grid.Unit>
                        )}
                        value={filters?.generalSubmitFeedback_Published_In?.map((value) =>
                          value === true ? 'true' : 'false',
                        )}
                        onChange={(selected) =>
                          setFieldValue(
                            'filters.generalSubmitFeedback_Published_In',
                            selected.map((selectedValue) => (selectedValue === 'true' ? true : false)),
                          )
                        }
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'오류 신고'}>
                      <EnumPairCheckboxGroupField
                        typename={'TaskErrorTypeEnum'}
                        name={'filters.errorType_In'}
                        label={'오류 신고 타입'}
                        renderContainer={(children) => (
                          <View sx={{ paddingX: 2 }}>
                            <Grid gapX={2}>{children}</Grid>
                          </View>
                        )}
                        renderOptionWrapper={(children, { id }) => (
                          <Grid.Unit key={id} size={'min'}>
                            {children}
                          </Grid.Unit>
                        )}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'자동 타이핑'}>
                      <CheckboxGroupField
                        name={'filters.typingAutomationScript_Exists_In'}
                        label={'일반'}
                        options={[
                          { id: 'true', text: 'O' },
                          { id: 'false', text: 'X' },
                        ]}
                        renderContainer={(children) => (
                          <View sx={{ paddingX: 2 }}>
                            <Grid gapX={2}>{children}</Grid>
                          </View>
                        )}
                        renderOptionWrapper={(children, { id }) => (
                          <Grid.Unit key={id} size={'min'}>
                            {children}
                          </Grid.Unit>
                        )}
                        value={filters?.typingAutomationScript_Exists_In?.map((value) =>
                          value === true ? 'true' : 'false',
                        )}
                        onChange={(selected) =>
                          setFieldValue(
                            'filters.typingAutomationScript_Exists_In',
                            selected.map((selectedValue) => (selectedValue === 'true' ? true : false)),
                          )
                        }
                      />
                    </QueryFormik.FilterGridUnit>
                  </QueryFormik.FilterGrid>
                </View>
                <View sx={{ marginTop: 5 }}>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<task_tasksQuery>>
                        {(queryReference) => (
                          <TaskWindowPaginator
                            fragmentReference={queryReference}
                            onLoadPage={(page) => {
                              setParsedUrlQuery({ ...filters, order, page });
                            }}
                          >
                            {({ tasks }, { renderPagination }) => (
                              <>
                                <View>
                                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                    {`총 ${numberWithCommas(tasks.totalCount || 0)}개${
                                      selectedTasks.length > 0 ? ` / ${selectedTasks.length}개` : ''
                                    }`}
                                  </Text>
                                </View>
                                <View sx={{ marginTop: 3 }}>
                                  <TaskConnectionDataTable
                                    taskConnection={tasks}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState
                                          title={'필터에 맞는 결과가 없어요'}
                                          description={'다른 필터로 다시 시도해보세요.'}
                                        />
                                      </View>
                                    }
                                    renderHead={(columns) => (
                                      <View
                                        as={'thead'}
                                        sx={{
                                          borderBottomWidth: 1,
                                          borderBottomStyle: 'solid',
                                          borderBottomColor: 'border.default',
                                          backgroundColor: 'canvas.subtle',
                                        }}
                                      >
                                        <View as={'tr'}>
                                          <View as={'th'} sx={{ padding: 2 }}>
                                            <Checkbox
                                              checked={selectedTasks.length === tasks.edges.length}
                                              onChange={(e) => {
                                                setSelectedTasks(
                                                  e.target.checked
                                                    ? tasks.edges.map(({ node }) => ({
                                                        id: node.id,
                                                        status: node.status,
                                                      }))
                                                    : [],
                                                );
                                              }}
                                            />
                                          </View>
                                          {columns.map(({ field, title, width }) => (
                                            <View
                                              key={field}
                                              as={'th'}
                                              sx={{
                                                borderWidth: 1,
                                                borderStyle: 'solid',
                                                borderColor: 'border.default',
                                                minWidth: width,
                                                textAlign: 'start',
                                                padding: 2,
                                                fontWeight: 'bold',
                                                color: 'fg.muted',
                                              }}
                                            >
                                              {title}
                                            </View>
                                          ))}
                                        </View>
                                      </View>
                                    )}
                                    renderRowWrapper={(children, row) => {
                                      const isSelectedTask = !!selectedTasks.find(({ id }) => id === row.id);
                                      return (
                                        <RowWrapper
                                          key={row.id}
                                          sx={{
                                            'cursor': 'pointer',
                                            ':hover': {
                                              backgroundColor: 'canvas.inset',
                                              transition: 'background-color 250ms',
                                            },
                                          }}
                                          onClick={() => {
                                            const pathname = `/task/${row.id}`;
                                            router.push(
                                              { pathname, query: normalizeObject({ ...filters, order }) },
                                              pathname,
                                            );
                                          }}
                                        >
                                          <View
                                            as={'td'}
                                            sx={{
                                              cursor: 'default',
                                              borderWidth: 1,
                                              borderStyle: 'solid',
                                              borderColor: 'border.default',
                                              padding: 2,
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <Checkbox
                                              checked={isSelectedTask}
                                              onChange={() => {
                                                setSelectedTasks(
                                                  isSelectedTask
                                                    ? selectedTasks.filter((selectedTask) => selectedTask.id !== row.id)
                                                    : [...selectedTasks, { id: row.id, status: row.status }],
                                                );
                                              }}
                                            />
                                          </View>
                                          {children}
                                        </RowWrapper>
                                      );
                                    }}
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.()}
                                </View>
                              </>
                            )}
                          </TaskWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            );
          }}
        </QueryFormik>
      </ErrorBoundary>
    </View>
  );
};

const CourseTitle = ({ ...variables }: task_CourseTitle_courseQuery$variables) => {
  const [{ course }] = useLazyLoadQuery<task_CourseTitle_courseQuery>(
    graphql`
      query task_CourseTitle_courseQuery($id: ID!) {
        course(id: $id) {
          id
          title
        }
      }
    `,
    { ...variables },
    { fetchPolicy: 'store-or-network' },
  );

  return <>{course.title}</>;
};

Task.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
Task.authenticated = true;
Task.routes = [
  { id: 'task', pathname: '/task', name: '문제 조회 · 배정', icon: PackageIcon, permissions: ['task_read'] },
  ...TaskId.routes,
];

export default Task;
