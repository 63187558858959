import { FileDiffIcon, SearchIcon, SyncIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { ComponentProps, Suspense } from 'react';
import { graphql } from 'react-relay';

import Button from '../../components/core/Button';
import EmptyState from '../../components/core/EmptyState';
import EnumPairSearchOverlayTokenField from '../../components/core/EnumPairSearchOverlayTokenField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import SchoolExamProblemConnectionDataTable from '../../components/schoolExamProblem/SchoolExamProblemConnectionDataTable';
import SchoolExamProblemWindowPaginator from '../../components/schoolExamProblem/SchoolExamProblemWindowPaginator';
import UserPaginatorSearchOverlayTokenField from '../../components/user/UserPaginatorSearchOverlayTokenField';
import { usePaginationContext } from '../../contexts/PaginationContext';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import {
  SchoolExamProblemStatusEnum,
  SchoolExamProblemSubjectEnum,
  schoolExamProblem_SchoolExamProblemsQuery,
} from '../../relay/__generated__/schoolExamProblem_SchoolExamProblemsQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { NextPage } from '../_app';

import SchoolExamProblemId from './[schoolExamProblemId]';

const schoolExamProblemsForSchoolExamProblem = graphql`
  query schoolExamProblem_SchoolExamProblemsQuery(
    $filters: SchoolExamProblemFilter
    $order: SchoolExamProblemOrder
    $page: Int
    $pageSize: Int
  ) {
    ...SchoolExamProblemWindowPaginator_query
      @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

type Props = {};

const SchoolExamProblem: NextPage<Props> = () => {
  const router = useRouter();

  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    status_In: { type: 'string', multiple: true },
    problemSubject_In: { type: 'string', multiple: true },
    modifiedById_In: { type: 'string', multiple: true },
    order: { type: 'string' },
    page: { type: 'number' },
  });

  const { search, status_In, problemSubject_In, modifiedById_In, order, page } = initialValues;

  const { pageSize } = usePaginationContext();

  return (
    <View>
      <Head title={'TCMS'} siteTitle={'시험지 검수'} />
      <ErrorBoundary>
        <QueryFormik<schoolExamProblem_SchoolExamProblemsQuery>
          query={schoolExamProblemsForSchoolExamProblem}
          staticVariables={{ pageSize }}
          initialValues={{
            filters: {
              search,
              status_In: status_In as SchoolExamProblemStatusEnum[],
              problemSubject_In: problemSubject_In as SchoolExamProblemSubjectEnum[],
              modifiedById_In,
            },
            order: order || 'created',
            page,
          }}
          options={{ fetchPolicy: 'store-and-network' }}
          onSubmit={(values) => setParsedUrlQuery({ ...values.filters, order }, { scroll: false })}
          enableReinitialize
        >
          {({ values: { filters, order }, setFieldValue, submitForm, resetForm }, queryReference) => {
            const handleReset = () => {
              resetForm({ values: { order } });
              setParsedUrlQuery({ order }, { scroll: false });
            };

            const handleChangeOrderActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
              newValue,
            ) => {
              setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
            };

            return (
              <View>
                <Text as={'h1'}>시험지 검수</Text>
                <Grid>
                  <Grid.Unit size={'min'}>
                    <QueryFormik.FilterSearchTextField
                      typename={'SchoolExamProblemFilter'}
                      label={'Search'}
                      labelConfig={{ visuallyHidden: true }}
                      name={'filters.search'}
                      size={'large'}
                      autoComplete={'off'}
                      leadingVisual={SearchIcon}
                      debounce
                      onChange={(e) => {
                        setFieldValue('filters.search', e.target.value);
                        setTimeout(() => submitForm(), 0);
                      }}
                    />
                  </Grid.Unit>
                  <Grid.Unit size={'max'}>
                    <View sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Stack gapX={2}>
                        <Stack.Item>
                          <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleReset}>
                            초기화
                          </Button>
                        </Stack.Item>
                        <Stack.Item>
                          <QueryFormik.OrderActionMenuButton
                            typename={'SchoolExamProblemOrder'}
                            orders={['created']}
                            onChange={handleChangeOrderActionMenu}
                          />
                        </Stack.Item>
                      </Stack>
                    </View>
                  </Grid.Unit>
                </Grid>
                <View sx={{ marginTop: 5 }}>
                  <QueryFormik.FilterGrid
                    onChange={(filters) => {
                      setParsedUrlQuery({ ...filters, order }, { scroll: false });
                    }}
                  >
                    <QueryFormik.FilterGridUnit size={1} title={'상태'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'SchoolExamProblemStatusEnum'}
                        label={'상태'}
                        name={'filters.status_In'}
                        placeholder={'상태 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'과목'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'SchoolExamProblemSubjectEnum'}
                        label={'과목'}
                        name={'filters.problemSubject_In'}
                        placeholder={'과목 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'검수자'}>
                      <UserPaginatorSearchOverlayTokenField
                        label={'검수자'}
                        name={'filters.modifiedById_In'}
                        placeholder={'검수자 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                  </QueryFormik.FilterGrid>
                </View>
                <View sx={{ marginTop: 5 }}>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<schoolExamProblem_SchoolExamProblemsQuery>>
                        {(queryReference) => (
                          <SchoolExamProblemWindowPaginator
                            fragmentReference={queryReference}
                            onLoadPage={(page) => setParsedUrlQuery({ ...filters, order, page })}
                          >
                            {({ schoolExamProblems }, { renderPagination }) => (
                              <>
                                <View>
                                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                    총 {numberWithCommas(schoolExamProblems.totalCount || 0)}개
                                  </Text>
                                </View>
                                <View sx={{ marginTop: 3 }}>
                                  <SchoolExamProblemConnectionDataTable
                                    schoolExamProblemConnection={schoolExamProblems}
                                    onRowClick={({ id }) => router.push({ pathname: `/schoolExamProblem/${id}` })}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState
                                          title={'필터에 맞는 결과가 없어요'}
                                          description={'다른 필터로 다시 시도해보세요.'}
                                        />
                                      </View>
                                    }
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.()}
                                </View>
                              </>
                            )}
                          </SchoolExamProblemWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            );
          }}
        </QueryFormik>
      </ErrorBoundary>
    </View>
  );
};

SchoolExamProblem.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
SchoolExamProblem.authenticated = true;
SchoolExamProblem.routes = [
  {
    id: 'schoolExamProblem',
    icon: FileDiffIcon,
    pathname: '/schoolExamProblem',
    name: '시험지 검수',
    permissions: ['school_exam_problem_read'],
  },
  ...SchoolExamProblemId.routes,
];

export default SchoolExamProblem;
